import {Grid} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useQuery} from "react-query";
import {Notifier} from "../../../components";
import BlockWard from "../../../components/BlockWard";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import {INotifierProps} from "../../../components/Notifier/Notifier";
import UnityBlock from "../../../components/Unity/UnityBlock";
import UnityListWard from "../../../components/Unity/UnityListWard";
import {GlobalContext} from "../../../contexts";
import useUnits from "../../../services/useUnit";

function Units() {
    const [notifier, setNotifier] = useState<INotifierProps>(null);
    const {view} = useContext(GlobalContext);
    const [page, setPage] = useState(2);
    const {getUnitsPage, deleteUnit, getUnits} = useUnits()
    const [openDelete, setOpenDelete] = React.useState<{ open: boolean, valueToDelete: any }>({
        open: false,
        valueToDelete: null
    });

    const currents = useQuery(['units', page], getUnitsPage, {
        refetchInterval: 60000,
    })

    // const currents = getUnits

    function handleCloseDelete(): void {
        setOpenDelete({open: false, valueToDelete: null});
    }

    function handlePaginator(page: number) {
        setPage(page)
    }

    function handleDelete() {
        deleteUnit(openDelete.valueToDelete).then(
            () => setNotifier({open: true, severity: 'success', msg: 'Cadastro deletado com sucesso!'}),
            () => setNotifier({open: true, severity: 'error', msg: 'Erro ao deletar cadastro.'}),
        );
        handleCloseDelete();
    }

    useEffect(() => {
        currents.refetch();
    }, [page])

    if (currents.isSuccess && currents?.data?.length) {

        if (view === "block")
            return (
                <Grid
                    container
                    gap={2}
                    padding={view === "block" ? "20px" : ""}
                    sx={{
                        overflowY: "auto",
                    }}
                >
                    <>

                        <BlockWard title="Unidades" _id="unidades">
                            {currents.data.map((item) => {
                                return (
                                    <UnityBlock data={item} key={item._id}/>
                                )
                            })}
                        </BlockWard>
                    </>
                </Grid>
            );
        else return <>
            <Notifier
                msg={notifier?.msg}
                closeState={setNotifier}
                severity={notifier?.severity}
                open={notifier?.open}
                closeTime={notifier?.closeTime}
            />
            <UnityListWard isPageable={true} setDelete={setOpenDelete} limit={currents.data.limit}
                           total={currents.data.total} handlePaginator={handlePaginator} data={currents.data}/>;
            <DeleteDialog open={openDelete.open} handleClose={handleCloseDelete} onConfirmDelete={handleDelete}
                          name="deleteDialog"></DeleteDialog>
        </>
    }
    return null;
}

export default Units;