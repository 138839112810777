import React, {useEffect, useRef, useState} from "react";
import {Form, Formik, useFormikContext} from "formik";
import Yup from "../../../configs/yup";
import {FormTextField} from "../../../components/Forms";
import {
    IConfigDevice,
    IDevice,
    IDeviceHistoryConfig,
    IFreeDevice,
    IpDevice
} from "../../../models/MonitoringDevices/types"
import {Notifier} from "../../../components";
import useMonitoringDevices from "../../../services/useMonitoringDevices";
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {Button} from "../../../components";
import {Autocomplete} from '@mui/material';
import useUnits from "../../../services/useUnit";

import {IUnit} from "../../../models/Unit/types";
import {useQuery, UseQueryResult} from "react-query";
import {IError} from "../../../models/Error/types";
import {INotifierProps} from "../../../components/Notifier/Notifier";
import useDevice from "../../../services/useDevice";
import _ from "lodash";


function ConfigForm() {
    const {config, getfreeDevices, deviceFailure, findIps} = useMonitoringDevices();
    const {getUnits} = useUnits();
    const [all, setAll] = useState<boolean>(false);
    const [notifier, setNotifier] = React.useState<INotifierProps>(null);
    const [unidadeselect, setUnidadeSelect] = useState<IUnit>({} as IUnit);
    const [deviceselect, setDeviceSelect] = useState<IDevice>({} as IDevice);
    const [unidades, setUnidades] = useState<any[]>([]);
    const [deviceIps, setDeviceIps] = useState<any[]>([]);
    const [device, setDevice] = useState<IFreeDevice>({} as IFreeDevice);
    const {deviceUpdateMutation} = useDevice();


    useEffect(() => {
        if (getUnits.isSuccess) {
            setUnidades(getUnits.data);
        }
    }, [getUnits])

    useEffect(() => {
        if (all) {
            getfreeDevices.mutate(unidadeselect, {
                onSuccess: (response) => {
                    setDevice(response.data);
                },
                onError: (error) => {
                    setNotifier({open: true, severity: 'error', msg: error.errorMessage});
                    setAll(false);
                }
            })
            findIps.mutate(deviceIps, {
                onSuccess: (response) => {
                    setDeviceIps(response.data);
                },
                onError: (error) => {
                    setNotifier({open: true, severity: 'error', msg: error.errorMessage});
                    setAll(false);
                }
            })
        }
    }, [all, unidadeselect])


    const initialValues: IConfigDevice = {
        ip: "",
        mac: "",
        idunidade: "",
        id: "",
        url: "",
        // time: '15000',
        // ssid: "",
        // password: "",
        // resolution: "",
        // calibration: ""
    }

    const times = [
        {label: "5 segundos", value: '5000'}, {label: "10 segundos", value: '10000'}, {
            label: "15 segundos",
            value: '15000'
        }
    ]

    const validation = Yup.object().shape({
        ip: Yup.string().required("informe o ip do dispositivo"),
        mac: Yup.string().required("informe o mac do dispositivo"),
        // unity: Yup.string().required("informe a unidade do dispositivo"),
        id: Yup.string().required("informe o id do dispositivo"),
        // time: Yup.string().required("informe o tempo de requisição do dispositivo"),
        // ssid: Yup.string().required("informe o ssid do wifi do dispositivo"),
        // password: Yup.string().required("informe a senha do wifi do dispositivo"),
        // url: Yup.string().required("informe a url do dispositivo"),
        // resolution: Yup.string().required("informe a resolução do dispositivo"),
        // calibration: Yup.string().required("informe a % de calibração do dispositivo"),
    })

    const handlerClearConfig = () => {

    }

    const handlerSubmit = async (values: IConfigDevice, {resetForm}: any) => {
        delete values.idunidade
        const deviceObj = {
            _id: values.id,
            ...values
        }

        deviceUpdateMutation.mutate(deviceObj, {
            onSuccess: (response) => {
                setNotifier({open: true, msg: "Dispositivo Atualizado Com Sucesso!", severity: 'success'});
                resetForm();
                setAll(false);
            },
            onError: (error) => {
                setNotifier({open: true, msg: "Erro ao atualizar Dispositivo.", severity: 'error'});
            },
            onSettled: () => {
            },
        });

        // config.mutate(values, {
        //     onSuccess: (response) => {
        //         setNotifier({open: true, severity: 'success', msg: "Dispositivo Configurado"});
        //     },
        //     onError: (error) => {
        //         resetForm();
        //         setAll(false)
        //         setNotifier({open: true, severity: 'error', msg: error.errorMessage});
        //     }
        // })
    }

    const SetValues = () => {
        const formik = useFormikContext();

        useEffect(() => {
            formik.resetForm();
            if (getfreeDevices.isSuccess) {

                formik.setFieldValue("id", device._id)
                // formik.setFieldValue("ssid", device.unity.company_id.ssid)
                // formik.setFieldValue("password", device.unity.company_id.password)
                // formik.setFieldValue("url", device.url)
                // formik.setFieldValue("resolution", device.resolution)
                // formik.setFieldValue("time", String(device.time))
                // formik.setFieldValue("calibration", device.calibration)
            }
            if (findIps.isSuccess && deviceIps.length > 0) {
                if (device.ip) {
                    formik.setFieldValue('mac', device.mac)
                    formik.setFieldValue('ip', device.ip)
                }
            }
            if (device?.unity?._id) {
                formik.setFieldValue('idunidade', device.unity._id)
            }
        }, [getfreeDevices, findIps])
        return null;
    };

    return (
        <Formik
            onSubmit={handlerSubmit}
            initialValues={initialValues}
            validationSchema={validation}

        >
            {({values, initialValues, setFieldValue, resetForm, errors, touched}) => (
                <Form style={{width: "100%"}}>
                    <SetValues/>
                    <Notifier severity={notifier?.severity ? notifier?.severity : "error"} msg={notifier?.msg} open={notifier?.open} closeTime={notifier?.closeTime}
                              closeState={setNotifier}/>
                    <Grid container spacing={4} maxWidth={600}>

                        <Grid item xs={12}>
                            <Autocomplete
                                id="idunidade"
                                options={unidades}
                                getOptionLabel={(option) => `${option.unity}-${option.bed}`}
                                renderInput={(params) => <TextField {...params} autoFocus
                                                                    error={notifier?.severity === 'error'}
                                                                    label="Unidade" variant="outlined"
                                />}
                                onChange={(event: any, newValue: any) => {
                                    if (newValue) {
                                        setFieldValue(
                                            "idunidade", newValue._id
                                        );
                                        setAll(true);
                                        setUnidadeSelect(newValue);
                                    } else {
                                        setAll(false);
                                    }

                                }}
                                fullWidth
                            />

                        </Grid>
                        {all && (
                            <>
                                {/*<Grid item xs={12}>*/}
                                {/*    <Autocomplete*/}
                                {/*        id="ip"*/}
                                {/*        options={deviceIps}*/}
                                {/*        isOptionEqualToValue={(option, value) => option.ip === value.ip}*/}
                                {/*        getOptionLabel={(option) => option.ip}*/}
                                {/*        renderInput={(params) => <TextField {...params} autoFocus*/}
                                {/*                                            error={notifier?.severity === 'error'}*/}
                                {/*                                            label="Ip do dispositivo"*/}
                                {/*                                            variant="outlined"*/}
                                {/*        />}*/}
                                {/*        onChange={(event: any, newValue: any) => {*/}
                                {/*            if (newValue) {*/}
                                {/*                setFieldValue(*/}
                                {/*                    "ip", newValue.ip,*/}
                                {/*                );*/}
                                {/*            }*/}
                                {/*        }}*/}
                                {/*        fullWidth*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField error={(touched.id && !!errors.mac)} name="mac"
                                                   label="Mac do dispositivo"
                                                   value={values.mac ? values.mac : ''}
                                                   onChange={(event) => {
                                                       setFieldValue('mac', event.target.value ? event.target.value : '', false)
                                                       setFieldValue('ip', _.find(deviceIps, {mac:event.target.value})?.ip || '', false)
                                                   }}
                                                   select>
                                            {deviceIps.map((option) =>
                                                (<MenuItem value={option.mac}>{option.mac}</MenuItem>)
                                            )}
                                        </TextField>
                                        <FormHelperText
                                            sx={{color: (theme) => theme.palette.warning.main}}>{(touched.id && !!errors.mac) ? errors.mac : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField label="Ip do dispositivo" name="ip" value={values?.ip} disabled/>
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <TextField error={(touched.id && !!errors.ip)} name="ip"*/}
                                {/*                   label="Ip do dispositivo"*/}
                                {/*                   value={values.ip ? values.ip : ''}*/}
                                {/*                   onChange={(event) => setFieldValue('ip', event.target.value ? event.target.value : '', false)}*/}
                                {/*                   select>*/}
                                {/*            {deviceIps.map((option) =>*/}
                                {/*                (<MenuItem value={option.ip}>{option.ip}</MenuItem>)*/}
                                {/*            )}*/}
                                {/*        </TextField>*/}
                                {/*        <FormHelperText*/}
                                {/*            sx={{color: (theme) => theme.palette.warning.main}}>{(touched.id && !!errors.ip) ? errors.ip : ''}*/}
                                {/*        </FormHelperText>*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <FormTextField label="Identificador de Dispositivo" name="id" value={values?.id}/>
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                    {/*<Autocomplete*/}
                                    {/*    id="time"*/}
                                    {/*    options={times}*/}
                                    {/*    getOptionLabel={(option) => option.label}*/}
                                    {/*    // defaultValue={{ label: "15 segundos", value: 15000 }}*/}
                                    {/*    value={values?.time}*/}
                                    {/*    isOptionEqualToValue={(option, value) => option.label === value.label}*/}
                                    {/*    renderInput={(params) => <TextField {...params} autoFocus*/}
                                    {/*        error={notifier?.severity === 'error'}*/}
                                    {/*        label="Tempo de Requisição"*/}
                                    {/*        variant="outlined"*/}
                                    {/*    />}*/}
                                    {/*    onChange={(event: any, newValue: any) => {*/}
                                    {/*        if (newValue) {*/}
                                    {/*            setFieldValue(*/}
                                    {/*                "time", newValue.value*/}
                                    {/*            );*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*    fullWidth*/}
                                    {/*/>*/}

                                    {/*<FormControl fullWidth>*/}
                                    {/*    <InputLabel id="_time">Tempo de Requisição</InputLabel>*/}
                                    {/*    <Select id='time' name="time" labelId="time"*/}
                                    {/*            value={values?.time ? values?.time : '15000'}*/}
                                    {/*            onChange={(event) => setFieldValue('time', event.target.value ? event.target.value : '15000')}*/}
                                    {/*            label="Tempo de Requisição">*/}
                                    {/*        <MenuItem value={'5000'}>5 segundos</MenuItem>*/}
                                    {/*        <MenuItem value={'10000'}>10 segundos</MenuItem>*/}
                                    {/*        <MenuItem value={'15000'}>15 segundos</MenuItem>*/}
                                    {/*    </Select>*/}
                                    {/*</FormControl>*/}

                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*    <FormTextField label="SSID" name="ssid" value={values?.ssid}/>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*    <FormTextField label="Password" name="password" value={values?.password}/>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*    <FormTextField label="Url" name="url" value={values?.url}/>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*    <FormTextField label="Resolução" name="resolution" value={values?.resolution}/>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*    <FormTextField label="% de Calibração" name="calibration"*/}
                                {/*                   value={values?.calibration}/>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <Button type="submit" loading={config.isLoading}>
                                        Configurar
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {config.isSuccess && (
                                        <Typography>
                                            Dispositivo configurado com sucesso
                                        </Typography>
                                    )}

                                </Grid>
                            </>

                        )}

                    </Grid>
                </Form>
            )}
        </Formik>

    )


}

export default ConfigForm;

// TODO Get The Url From The Right Ambient