import { Grid } from "@mui/material";
import React, { useContext } from "react";
import BlockWard from "../../components/Ward/BlockWard";
import ListWard from "../../components/Ward/ListWard";
import { GlobalContext } from "../../contexts";
import GlobalLoading from "../../helpers/GlobalLoading";
import useMonitoringDevices from "../../services/useMonitoringDevices";
import {useQuery} from "react-query";
function MonitoringDevices() {
  const { getMonitoringDevices } = useMonitoringDevices();

  const getMonitoringDevice = useQuery('monitoring', getMonitoringDevices, {
    refetchInterval: 5000,
  })

  const { view } = useContext(GlobalContext);
  if (getMonitoringDevice.isSuccess && getMonitoringDevice.data.length) {
    const config = { ...getMonitoringDevice.data };
    if (view === "block")
      return (
        <Grid
          container
          gap={2}
          padding={view === "block" ? "20px" : ""}
          sx={{
            overflowY: "auto",
          }}
        >
          {getMonitoringDevice.data.map((item) => (
            <>
              <BlockWard
                key={item._id}
                actives={item.actives}
                inatives={item.inatives}
                title={item.unity.toLocaleUpperCase()}
                _id={item._id}
                total={item.total}
                data={item.results}
              />
            </>
          ))}
        </Grid>
      );
    else return <ListWard data={getMonitoringDevice.data}></ListWard>;
  }
  return null;
}

export default MonitoringDevices;
