import React, { useEffect, useState } from "react";
import {
    Box,
    Dialog as MuiDialog,
    DialogActions,
    Pagination,
    Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IDeviceHistory } from "../../models/MonitoringDevices/types";
import { HomeMiniRounded } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { GlobalContext } from "../../contexts";
import { AnimatedOxygenLogo } from "../../helpers";
import { IClient } from "../../models/Client/types";
import { IDialogInfo } from "../../models/Global/types";
import { IUser } from "../../models/User/types";
import InfoLine from "../InfoLine";
import { Device } from "../../models/Device/types";
import { IProduct } from "../../models/Product/types";
import useProduct from "../../services/useProduct";

function DeviceInfo({ open, handleClose, data }: IDialogInfo<Device>) {
    const { page, setPage } = React.useContext(GlobalContext);
    const { getProduct } = useProduct();
    const [product, setProduct] = useState<IProduct>({} as IProduct)


    async function productName() {
        const product = await getProduct(data.product)
        setProduct(product);
    }

    useEffect(() => {
        productName();
    }, [data])

    return (
        <MuiDialog
            open={open}
            onClose={handleClose}
            sx={{
                "& .MuiDialogContent-root": {
                    padding: "16px 0px",

                },
                "& .MuiPaper-root": {
                    maxWidth: '70vw'
                }
            }}
        >
            <DialogTitle title="Cliente">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        padding: "0 50px"
                    }}
                >
                    <HomeMiniRounded color="primary" />
                    <Typography color="primary" fontWeight={"bold"}>
                        {data._id.toUpperCase()}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        gap: "2px",
                    }}
                >
                    <InfoLine label="ID" info={data._id}></InfoLine>
                    <InfoLine label="Produto" info={product.name}></InfoLine>
                    <InfoLine label="Livre" info={data.free ? 'SIM' : 'NÂO'}></InfoLine>
                    <InfoLine label="Resolução" info={data.resolution}></InfoLine>
                    <InfoLine label="Tempo" info={`${(data.time/1000)}s`}></InfoLine>
                    <InfoLine label="Volume Maximo" info={data.volume_max}></InfoLine>
                    <InfoLine label="Calibração" info={data.calibration}></InfoLine>
                    <InfoLine label="Status" info={data.active ? 'Ativo' : 'inativo'}></InfoLine>
                </Box>
            </DialogContent>
        </MuiDialog>
    );
}

export default DeviceInfo;
