import axios from "axios";
import {IError} from "../models/Error/types";

const instance = axios.create({
    baseURL:
        process.env.REACT_APP_API_MOCK ||
        process.env.REACT_APP_API_DEV ||
        process.env.REACT_APP_API_PROD,
    timeout: 10000,
    withCredentials: true,
});
instance.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },
    (error) => {
        if (
            (error.response.config.url !== '/auth/login') &&
            (error.response.status === 401)
        ) {
            localStorage.removeItem("user");
            window.location.href = "/login";
        }
        else if (
            (error.response.config.url !== '/auth/login') &&
            (error.response.status === 403)
        ) {
            window.location.href = "/forbidden";
        }
        const err: IError = {
            errorMessage: error.response
                ? error.response.data.errorMessage
                : error.message,
        };
        return Promise.reject(err);
    }
);
export default instance;
