import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
interface ListItemLinkProps {
  icon?: (value : boolean) => React.ReactElement;
  name: string;
  to?: string;
  children?: any;
}
function ListItemLink(props: ListItemLinkProps) {
  const [showChildrens, setShowChildrens] = useState(true);
  const { icon, name, to, children } = props;
  let resolved = useResolvedPath(to);
  const location = useLocation();
  let match = useMatch({ path: resolved?.pathname, end: true });

  function seeChildrens(){
    if(children){
      setShowChildrens(!showChildrens)
    }
  }
  return (
    <>
      <ListItem
        onClick={seeChildrens}
        component={NavLink}
        to={to}
        button
        sx={{
          "&::before": {
            content: '""',
            position: "absolute",
            width: "3px",
            height: "25px",
            left: 0,
            transition: ".3s",
            background:
              (match && to !== "#") || !showChildrens
                ? (theme) => theme.palette.primary.main
                : "transparent",
          },
        }}
      >
        <ListItemIcon color="red">{icon((
                          (location.pathname === to) || !showChildrens ? true : false
                        ))}</ListItemIcon>
        <ListItemText>
          <Typography
            color={
              !match || !(to !== "#" || !showChildrens) ? "secondary.light" : "primary"
            }
            fontFamily="Nunito"
            fontWeight="700"
            fontSize="1rem"
            whiteSpace={"nowrap"}
          >
            {name}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider variant="middle" />
      <List sx={{padding: '0 0 10px 15px'}} hidden={showChildrens}>
        <Divider variant="middle" />
        {children}
      </List>
    </>
  );
}

export default ListItemLink;
