import { Table, TableBody, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "../Button";
import { StyledTableCell, StyledTableRow } from "../Ward/ListWard";

interface PaginatorProps {
    handlePaginator: (page: number) => void,
    total: number;
    limit: number;
}

export default function Paginator({ handlePaginator, total = 1, limit = 1 }: PaginatorProps) {
    const [page, setPage] = useState(0);

    function nextPage() {
        if (page < Math.ceil(total / limit)) {
            setPage(page + 1)
        }
    }

    useEffect(() => {
        handlePaginator(page);
    }, [page])

    useEffect(() => {
        setPage(1)
    }, [])
    function prevPage() {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    return (
        <Table size="small">
            <TableBody>

                <StyledTableRow>
                    <StyledTableCell
                        align="center"
                        sx={{
                            color: (theme) => theme.palette.primary.main,
                            padding: '1.2em',
                            "& > *": { borderBottom: "unset" },
                            "&:hover": {
                                backgroundColor: "#fafafa !important",
                                cursor: "pointer",
                            },
                        }}
                        onClick={prevPage}
                        width={'25%'}

                    >
                        {'Pagina anterior'}
                    </StyledTableCell>

                    <StyledTableCell
                        align="center"
                        sx={{
                            color: (theme) => theme.palette.primary.main,
                            padding: '1.2em'
                        }}
                        onClick={() => null}
                        width={'50%'}

                    >
                        {`${page} / ${Math.ceil(total / limit)}`}
                    </StyledTableCell>

                    <StyledTableCell
                        align="center"
                        sx={{
                            color: (theme) => theme.palette.primary.main,
                            padding: '1.2em',
                            "& > *": { borderBottom: "unset" },
                            "&:hover": {
                                backgroundColor: "#fafafa !important",
                                cursor: "pointer",
                            },
                        }}
                        onClick={nextPage}
                        colSpan={2}
                        width={'25%'}
                    >
                        {'Proxima pagina'}
                    </StyledTableCell>
                </StyledTableRow>
            </TableBody>
        </Table>
    )
}