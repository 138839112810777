import { Box, Typography } from "@mui/material";

interface InfoLineProps {
    label: string,
    info: string | number
}

const InfoLine = ({ label, info }: InfoLineProps) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    padding: "10px",
                    gap: "2px",
                    justifyContent: "center",
                }}
            >
                <Typography color="primary" fontWeight="bold">
                    {label}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: "2px",
                    justifyContent: "center",
                }}
            >
                <Typography color="" fontWeight="bold">
                    {info}
                </Typography>
            </Box>
        </>
    )
}

export default InfoLine;