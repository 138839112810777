import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
interface Routes {
  path: string;
  tableLabel: string;
}
export const routes: Routes[] = [
  { path: "/", tableLabel: "Inicio" },
  { path: "/login", tableLabel: "Entrar" },
  { path: "/monitoring-device", tableLabel: "Meus Dispositivos" },
  { path: "/reports", tableLabel: "Meus Relatórios" },
  { path: "/settings", tableLabel: "Configurações" },
  { path: "/register", tableLabel: "Cadastro" },
  { path: "/clients", tableLabel: "Cadastro de Clientes" },
  { path: "/users", tableLabel: "Cadastro de Usuários" },
  { path: "/products", tableLabel: "Cadastro de Produtos" },
  { path: "/devices", tableLabel: "Cadastro de Dispositivos" },
  { path: "/prescription-itens", tableLabel: "Cadastro dos Itens de Prescrição" },
  { path: "/forbidden", tableLabel: "Proibido" },
];
function AppHelmet() {
  const location = useLocation();
  const [tabTitle, setTabTitle] = React.useState<string>("");
  React.useEffect(() => {
    const label = routes.find(
      (routeToFind) => routeToFind.path === location.pathname ?? routeToFind
    );
    if (label) {
      setTabTitle(label.tableLabel);
    } else {
      setTabTitle("404 Não Encontrado :/");
    }
  }, [location]);
  return (
    <HelmetProvider>
      <Helmet title={`Iottic - ${tabTitle}`} />
    </HelmetProvider>
  );
}
export default AppHelmet;
