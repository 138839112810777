import React from "react";
import { Form, Formik } from "formik";
import Yup from "../../configs/yup";
import { FormTextField } from "../../components/Forms";
import { Grid } from "@mui/material";
import { Button } from "../../components";
import { AuthContext } from "../../contexts";
import { IUserForm } from "../../models/Auth/types";
import { EmailRounded, LockRounded } from "@mui/icons-material";
import { Notifier } from "../../components";

function LoginForm() {
  const { authenticate, loading, notifier, setNotifier } = React.useContext(AuthContext);
  const initialValues: IUserForm = {
    email: "",
    password: "",
  };
  const validation = Yup.object({
    email: Yup.string().required("O seu email é necessário."),
    password: Yup.string().required("A sua senha é necessária."),
  });
  const handleSubmit = (values: IUserForm) => {
    authenticate(values);
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validation}
    >
      {({ values, initialValues }) => (
        <Form
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <Notifier severity="error" msg={notifier?.msg} open={notifier?.open} closeTime={notifier?.closeTime} closeState={setNotifier} />
          <Grid
            container
            spacing={4}
            sx={{
              maxWidth: { xs: "500px", xl: "800px" },
            }}
          >
            <Grid item xs={12}>
              <FormTextField
                label="Email"
                name="email"
                autoComplete={"email"}
                disabled={loading}
                adorment={{
                  element: (
                    <EmailRounded color={loading ? "disabled" : "primary"} />
                  ),
                  position: "end",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                label="Senha"
                name="password"
                type="password"
                disabled={loading}
                autoComplete={"current-password"}
                adorment={{
                  element: (
                    <LockRounded color={loading ? "disabled" : "primary"} />
                  ),
                  position: "end",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" loading={loading}>
                Entrar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
