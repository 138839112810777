import { InputAdornment, TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";

interface IFormTextFieldProps {
  label: string;
  name: string;
  type?: string;
  value?: any;
  autoComplete?: string;
  disabled?: boolean;
  adorment?: {
    element: JSX.Element | string;
    position: "start" | "end";
  };
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

function FormTextField({
  label,
  name,
  type,
  value,
  autoComplete,
  onBlur,
  disabled,
  adorment = { element: "", position: "end" },
}: IFormTextFieldProps) {
  const [field, meta] = useField({ name: name });
  const settings = {
    ...field,
    label: label + ":",
    fullWidth: true,
    InputLabelProps: { shrink: true },
    name,
    type,
    value,
    autoComplete,
    onBlur,
    disabled,
  };
  return (
    <TextField
      {...settings}
      error={meta.error && meta.touched ? true : false}
      helperText={meta.error && meta.touched ? meta.error : ""}
      variant="outlined"
      InputProps={
        adorment.position === "end"
          ? {
              endAdornment: (
                <InputAdornment position={adorment.position}>
                  {adorment.element}
                </InputAdornment>
              ),
            }
          : {
              startAdornment: (
                <InputAdornment position={adorment.position}>
                  {adorment.element}
                </InputAdornment>
              ),
            }
      }
    />
  );
}

export default FormTextField;
