import {useContext} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {AuthContext} from "../../contexts";
import {useLocalStorage} from "../../hooks";
import {ILoggedUser} from "../../models/Auth/types";
import {routes} from "../../helpers/AppHelmet";

interface RequireAuthProps {
    children: JSX.Element;
}

function RequireAuth({children}: RequireAuthProps) {
    const {access_token, roles} = useContext(AuthContext);
    const {get} = useLocalStorage<ILoggedUser>();
    const {access_token: local_access_token, roles: local_roles} = get('user')
    const location = useLocation();
    const label = routes.find(
        (routeToFind) => routeToFind.path === location.pathname ?? routeToFind
    );
    if (!access_token && label) {
        if (!local_access_token) {
            return <Navigate to={"/login"} replace={true}/>;
        }
    }
    if (roles?.find((roleToFind)=> roleToFind === 'iottic' ?? roleToFind) && location.pathname === '/') {
        return <Navigate to={"/clients"} replace={true}/>;
    }
    return children;
}

export default RequireAuth;
