import React from "react";
import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  Pagination,
  Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IDeviceHistory } from "../../models/MonitoringDevices/types";
import { HomeMiniRounded } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { GlobalContext } from "../../contexts";
import { AnimatedOxygenLogo } from "../../helpers";
import { IClient } from "../../models/Client/types";
import { IDialogInfo } from "../../models/Global/types";

function ClientInfo({ open, handleClose, data }: IDialogInfo<IClient>) {
  const { page, setPage } = React.useContext(GlobalContext);
  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialogContent-root": {
          padding: "16px 0px",

        },
        "& .MuiPaper-root": {
          maxWidth: '70vw'
        }
      }}
    >
      <DialogTitle title="Cliente">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            padding: "0 25px"
          }}
        >
          <HomeMiniRounded color="primary" />
          <Typography color="primary" fontWeight={"bold"}>
            {data.name.toUpperCase()}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            gap: "2px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="primary" fontWeight="bold">
              NOME
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="" fontWeight="bold">
              {data.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="primary" fontWeight="bold">
              CNPJ
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="" fontWeight="bold">
              {data.fiscal_number}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="primary" fontWeight="bold">
              SSID
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="" fontWeight="bold">
              {data.ssid}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="primary" fontWeight="bold">
              Responsável por:
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="" fontWeight="bold">
              {data.responsible}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="primary" fontWeight="bold">
              Usuário de Integração
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="" fontWeight="bold">
              {data.integration_user}
            </Typography>
          </Box>
            <Box
                sx={{
                    display: "flex",
                    padding: "10px",
                    gap: "2px",
                    justifyContent: "center",
                }}
            >
                <Typography color="primary" fontWeight="bold">
                    Host de Integração
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: "2px",
                    justifyContent: "center",
                }}
            >
                <Typography color="" fontWeight="bold">
                    {data.integration_host}
                </Typography>
            </Box>
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="primary" fontWeight="bold">
              Status
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              justifyContent: "center",
            }}
          >
            <Typography color="" fontWeight="bold">
              {data.active ? "Ativo" : "Inativo"}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </MuiDialog>
  );
}

export default ClientInfo;
