import { CloseRounded } from "@mui/icons-material";
import { DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { Button, FormTextField } from "../../../components";
import Notifier, { INotifierProps } from "../../../components/Notifier/Notifier";
import Yup, { YupUtils } from "../../../configs/yup";
import { Device } from "../../../models/Device/types";
import { IAddProps } from "../../../models/Global/types";
import { IProduct } from "../../../models/Product/types";
import useDevice from "../../../services/useDevice";
import useProduct from "../../../services/useProduct";

function DevicesForm({ setAdd, formData }: IAddProps) {
    const { deviceMutation, deviceUpdateMutation } = useDevice();
    const { getProducts } = useProduct();
    const [notifier, setNotifier] = React.useState<INotifierProps>();
    const initialValues : Device = formData ? formData : {
        free: false,
        time: '',
        resolution: '',
        volume_max: '',
        product: '',
        active: true
    }

    const validations = Yup.object().shape({
        product: Yup.string().required(YupUtils.mesages.REQUIRED_MESAGE),
    });
    function handleSubmit(values) {

        if (formData) {
            deviceUpdateMutation.mutate(values, {
                onSuccess: (response) => {
                    setNotifier({ open: true, msg: "Dispositivo Atualizado Com Sucesso!", severity: 'success' });
                    setTimeout(() => setAdd(false), 1000);
                },
                onError: (error) => {
                    setNotifier({ open: true, msg: "Erro ao atualizar Dispositivo.", severity: 'error' });
                },
                onSettled: () => { },
            });
            return
        }

        deviceMutation.mutate(values, {
            onSuccess: (response) => {
                setNotifier({ open: true, msg: "Dispositivo Cadastrado Com Sucesso!", severity: 'success' });
                setTimeout(() => setAdd(false)
                    , 1000);
            },
            onError: (error) => {
                setNotifier({ open: true, msg: "Erro ao cadastrar Dispositivo.", severity: 'error' });
            },
            onSettled: () => { },
        });
    }


    return (
        <>
            <Notifier
                msg={deviceMutation.error?.errorMessage || notifier?.msg}
                closeState={setNotifier}
                severity={notifier?.severity}
                open={notifier?.open}
                closeTime={notifier?.closeTime}
            />
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography fontSize={16} fontWeight="bold" color="secondary">
                    Adicionar Dispositivo
                </Typography>
                <IconButton onClick={() => setAdd(false)}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validations}
                >
                    {({ setFieldValue, values }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid xs={12} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="product">Produto</InputLabel>
                                        <Select name="product" labelId="product" value={values.product} onChange={(event) => setFieldValue('product', event.target.value.toLocaleString())} label="Produto">
                                            {getProducts.data.data.map((option) =>
                                                (<MenuItem value={option._id}>{option.name}</MenuItem>)
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="time" type="number" label="Tempo" value={values?.time} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="resolution" type="number" label="Resolução" value={values?.resolution} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="volume_max" type="number" label="Volume Maximo" value={values?.volume_max} ></FormTextField>
                                </Grid>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="free">Livre ?</InputLabel>
                                        <Select name="free" labelId="free" defaultValue={values.free.toString()} onChange={(event) => setFieldValue('free', JSON.parse(event.target.value))} label="Livre ?">
                                            <MenuItem value={'true'}>Sim</MenuItem>
                                            <MenuItem value={'false'}>Não</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="active">Status</InputLabel>
                                        <Select name="active" labelId="active" defaultValue={values.active.toString()} onChange={(event) => setFieldValue('active', JSON.parse(event.target.value))} label="Status">
                                            <MenuItem value={'true'}>Ativo</MenuItem>
                                            <MenuItem value={'false'}>Inativo</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} item>
                                    <Button type={"submit"} loading={deviceMutation.isLoading}>
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </>
    );
}

export default DevicesForm;