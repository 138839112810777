import React from "react";
import { Alert, Snackbar } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";

type TransitionProps = Omit<SlideProps, "direction">;

export interface INotifierProps {
  msg: string;
  severity: "error" | "success" | "warning" | "info";
  closeTime?: number;
  closeState?: React.Dispatch<React.SetStateAction<INotifierProps>>;
  open: boolean;
}
function Notifier({
  msg,
  open,
  severity,
  closeTime = 3000,
  closeState,
  ...props
}: INotifierProps) {
  function handleClose() {
    closeState(null);
  }
  function TransitionRight(props: TransitionProps) {
    return <Slide {...props} direction="right" />;
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={closeTime}
      onClose={handleClose}
      TransitionComponent={TransitionRight}
      {...props}
    >
      <Alert severity={severity}>{msg}</Alert>
    </Snackbar>
  );
}

export default React.memo(Notifier);
