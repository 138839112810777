import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  IDevice,
  IDeviceHistoryConfig,
  ITotal,
  IWard,
} from "../../models/MonitoringDevices/types";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { Delete, Edit, HistoryOutlined } from "@mui/icons-material";
import { parseISO, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { GlobalContext } from "../../contexts";
import { useQuery, UseQueryResult } from "react-query";
import { IError } from "../../models/Error/types";
import Dialog from "../Dialog/Dialog";
import { IOSSwitch, StyledTableCell } from "../Ward/ListWard";
import { IClient } from "../../models/Client/types";
import { Tooltip } from "@mui/material";
import { IUser } from "../../models/User/types";
import { ListWardProps, RowProps } from "../../models/Global/types";
import useClient from "../../services/useClient";
import Paginator from "../Paginator";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: "none",
  padding: "10px 0px",
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface IColumns {
  name: string;
  align: "left" | "center" | "right" | "justify" | "inherit" | undefined;
}
const columns: IColumns[] = [
  {
    name: "NOME",
    align: "left",
  },
  {
    name: "ROLES",
    align: "center",
  },
  {
    name: "STATUS",
    align: "center",
  },
  {
    name: "EDITAR",
    align: "center",
  },
  {
    name: "EXCLUIR",
    align: "center",
  },
];

function Row(props: RowProps<IUser>) {

  const data: IUser = props.data;
  const [open, setOpen] = React.useState(false);
  const { getClient } = useClient();
  const { add, setAdd, setFormData } = useContext(GlobalContext);
  const [client, setClient] = useState<IClient[]>([]);

  function handleEdit() {
    setFormData(props.data);
    setAdd(true);
  }

  async function clientName() {
    let result = []
    data.companies_links.map((link) => {
      getClient(link.company_id).then((value) => result.push(value))
    })
    setClient(result);
  }

  useEffect(() => {
    clientName()
  }, [data])


  return (
    <React.Fragment>
      <StyledTableRow
        sx={(theme) => {
          return {
            backgroundColor:
              props.index % 2 === 0 ? "#f3f3f3 !important" : "white !important",
            "& > *": { borderBottom: "unset" },
            borderBottom: open ? `1px solid ${theme.palette.primary.main}` : "",
            "&:hover": {
              backgroundColor: "#fafafa !important",
              cursor: "pointer",
            },
          };
        }}
      >
        <StyledTableCell
          align="left"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={() => setOpen(!open)}
        >
          {data.name}
        </StyledTableCell>
        <StyledTableCell align="center" onClick={() => setOpen(!open)}>{data.roles?.map((value, index) => index ? ' - ' + value : value)}</StyledTableCell>
        <StyledTableCell align="center" onClick={() => setOpen(!open)}>
          {data.active ? (
            <IOSSwitch checked={data.active} />
          ) : (
            <IOSSwitch disabled />
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Tooltip title="Editar Cliente">
            <Edit
              onClick={handleEdit}
              sx={{
                fontSize: "1.9em",
                color: (theme) => theme.palette.primary.main,
                trasition: ".3s",
                cursor: "pointer",
                "&:hover": {
                  color: (theme) => theme.palette.primary.light,
                },
                "&.Mui-selected": {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
            />
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell align="center" width={'10%'}>
          <Tooltip title="Deletar Cliente">
            <Delete
              onClick={() => { props.openDelete({ open: true, valueToDelete: data }); }}
              sx={{
                fontSize: "1.9em",
                color: (theme) => theme.palette.error.main,
                trasition: ".3s",
                cursor: "pointer",
                "&:hover": {
                  color: (theme) => theme.palette.error.light,
                },
                "&.Mui-selected": {
                  color: (theme) => theme.palette.error.main,
                },
              }}
            />
          </Tooltip>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases" sx={{ mb: "20px" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">Cor de fundo</StyledTableCell>
                    <StyledTableCell align="center">Status de verificação</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow key={data._id} sx={{ height: "4em" }}>
                    <StyledTableCell align="center" width="25%">{data.email}</StyledTableCell>
                    <StyledTableCell align="center" width="25%">{data.background_color}</StyledTableCell>
                    <StyledTableCell align="center" width="25%">{data.verified ? 'Verificado' : 'Não verificado'}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
            <Box>
              <Table size="small" aria-label="purchases" sx={{ mb: "20px" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Vinculo com</StyledTableCell>
                    <StyledTableCell align="center">Permissão do vinculo</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.companies_links.map((companies_link, index) =>
                    <StyledTableRow key={companies_link.company_id} sx={{ height: "4em" }}>
                      <StyledTableCell align="center" width="25%">{client[index]?.name}</StyledTableCell>
                      <StyledTableCell align="center" width="25%">{companies_link?.rights}</StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function UserListWard({ data, setDelete, handlePaginator, total, limit, isPageable }: ListWardProps<IUser>) {

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <StyledTableCell key={item.name} align={item.align}>
                {item.name.toUpperCase()}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <Row
              data={item}
              index={index}
              key={index}
              openDelete={setDelete}
            />
          ))}
        </TableBody>
      </Table>
      {isPageable &&
        <Paginator total={total} limit={limit} handlePaginator={handlePaginator}></Paginator>}
    </>
  );
}
export default UserListWard;
