import axios from "axios";
import React, { createContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { INotifierProps } from "../components/Notifier/Notifier";
import instance from "../configs/api";
import { useLocalStorage } from "../hooks";
import {
  IAuthProvider,
  IAuthContext,
  ILoggedUser,
  IUserForm,
} from "../models/Auth/types";
import useAuth from "../services/useAuth";
import {GlobalContext} from "./GlobalContext";
export const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export const AuthProvider = ({ children }: IAuthProvider) => {
  const { loginMutation } = useAuth();
  const [user, setUser] = React.useState<ILoggedUser | null>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [notifier, setNotifier] = React.useState<INotifierProps>(null);
  const { set, get, remove } = useLocalStorage<ILoggedUser>();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<any>();
  ref.current = () => {
    const userLocal: ILoggedUser = get("user");
    if (Object.keys(userLocal).length) {
      setUser(userLocal);
      instance.defaults.headers.common = {
        Authorization: `bearer ${userLocal.access_token}`,
        company_id: userLocal.company_id ? userLocal.company_id : "",
      };
      // if (location.state) {
      //   navigate("/", { replace: true, state: { from: location } });
      // }
    }
  };
  React.useEffect(() => {
    ref.current();
  }, []);
  async function authenticate(values: IUserForm) {
    setLoading(true);
    loginMutation.mutate(values, {
      onSuccess: (response) => {
        set("user", response);
        setUser(response);
        instance.defaults.headers.common = {
          Authorization: `bearer ${response.access_token}`,
          company_id: response.company_id ? response.company_id : "",
        };
        navigate("/", { replace: true, state: { from: location } });
      },
      onError: (error) => {
        setNotifier({ open: true, severity: 'error', msg: error.errorMessage })
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  }
  async function logout() {
    remove("user");
    setUser({});
    navigate("/login", { replace: true });
  }
  return (
    <AuthContext.Provider
      value={{
        ...user,
        loading,
        notifier,
        setNotifier,
        authenticate,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
