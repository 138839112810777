import {Routes, Route} from "react-router-dom";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import Sidebar from "./components/Sidebar/Sidebar";
import {AppHelmet} from "./helpers";
import {Home, NotFound, Login} from "./pages";
import MonitoringDevices from "./pages/MonitoringDevices/MonitoringDevices";
import Clients from "./pages/Register/Clients/Clients";
import Devices from "./pages/Register/Devices/Devices";
import Products from "./pages/Register/Products/Products";
import Units from "./pages/Register/Units/Units";
import Prescriptions from "./pages/Register/Prescriptions/Prescriptions";
import Users from "./pages/Register/Users/Users";
import Reports from "./pages/Reports/Reports";
import Settings from "./pages/Settings/Settings";

function AppRoutes() {
    return (
        <>
            <AppHelmet/>
            <Routes>
                <Route element={
                    <RequireAuth>
                        <Sidebar/>
                    </RequireAuth>
                }>
                    <Route
                        path="/"
                        element={
                            // <RequireAuth>
                            <Home/>
                            // </RequireAuth>
                        }
                    />
                    <Route path="/monitoring-device" element={<MonitoringDevices/>}/>
                    <Route path="/settings" element={<Settings/>}/>
                    <Route path="/reports" element={<Reports/>}/>
                    <Route path="/clients" element={<Clients/>}/>
                    <Route path="/users" element={<Users/>}/>
                    <Route path="/products" element={<Products/>}/>
                    <Route path="/devices" element={<Devices/>}/>
                    <Route path="/units" element={<Units/>}/>
                    <Route path="/prescription-itens" element={<Prescriptions/>}/>
                    <Route path="/forbidden" element={<NotFound/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Route>
                <Route path="/login" element={<Login/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    );
}

export default AppRoutes;
