import axios from "axios";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { IPrescription } from "../models/Prescription/types";
import { IError } from "../models/Error/types";


function usePrescriptions(){
    const getPrescriptions: UseQueryResult<IPrescription[], IError> = useQuery(
        "prescription",
        async ()=>{
            return instance.get("/prescription/get").then((res)=> res.data);
        }
    )

    async function getPrescriptionsPage({queryKey}){
        return instance.get(`/prescription/get?page=${queryKey[1]}`).then((res) => res.data);
    }


    const prescriptionMutation: UseMutationResult<any, IError, any> =
        useMutation(async (values) => {
            return instance.post("/prescription/store", values).then((res) => res.data);
        });

    const prescriptionUpdateMutation: UseMutationResult<any, IError, any> =
        useMutation(async (values) => {
            return instance.put(`/prescription/update/${values._id}`, values).then((res) => res.data);
        });


    const deletePrescription = (prescription: IPrescription) => {
        return instance.delete(`/prescription/delete/${prescription._id}`);
    }

    return {
        getPrescriptions,
        getPrescriptionsPage,
        deletePrescription,
        prescriptionMutation,
        prescriptionUpdateMutation
    }
}
export default usePrescriptions;
