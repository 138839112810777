import React from "react";
import { BlockProps } from "../../models/Global/types";
import { IUser } from "../../models/User/types";
import Block from "../Block";
import UserInfo from "./UserInfo";

function UserBlock({ data }: BlockProps<IUser>) {
    const [open, setOpen] = React.useState<boolean>(false);

    function handleOpenModal() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    return <>
        <UserInfo
            open={open}
            // loading={getDevice.isLoading}
            data={data}
            handleClose={handleClose}
        />
        <Block data={data} handleOpenModal={setOpen}></Block>

    </>
}

export default UserBlock;