import { useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { IError } from "../models/Error/types";
import { IRequestConfig } from "../models/Global/types";
import { Roles } from "../models/User/types";

function useRole() {
    
    const allRoles: UseQueryResult<IRequestConfig<string[]> ,IError> = useQuery(
        "role",
        async () => {
          return instance.get("role/get").then((res) => res.data);
        },
        {
          refetchInterval: 60000,
        }
      );

    return {allRoles}
    
  }
  
  export default useRole;