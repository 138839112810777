import { Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { IBlockWard } from "../../models/Global/types";


function BlockWard({ title, _id , children } : IBlockWard) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [more, setMore] = React.useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose(): void {
    setOpen(false);
  }
  return (
    <>
      <Grid
        item
        xs={12}
        container
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: grey[50],
          padding: "10px",
          borderRadius: "5px",
          border: `1px solid ${grey[200]}`,
          gap: "10px",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            fontFamily={"Nunito"}
            fontWeight={"bold"}
            color="primary"
            fontSize={".825em"}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
}

export default BlockWard;
