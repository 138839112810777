import {
    ArrowBackIosRounded,
    ArrowForwardIosRounded,
    Business,
    CloseRounded,
    LogoutRounded,
    Notifications,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItemButton,
    Tooltip,
    Typography,
} from "@mui/material";
import React, {useContext} from "react";
import {AuthContext, GlobalContext} from "../../contexts";
import {AnimatedOxygenLogo} from "../../helpers";
import Popover from "@mui/material/Popover";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {useLocation, useNavigate} from "react-router-dom";
import instance from "../../configs/api";

function SidebarUserProfile() {
    const {name, logout, background_color} = useContext(AuthContext);
    const [company, setCompany] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(true);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const {sidebar, setSidebar} = React.useContext(GlobalContext);
    const {companies_links, access_token} = React.useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();

    function handleCompanyChange(_id:string) {
        setCompany(false)
        instance.defaults.headers.common = {
            Authorization: `bearer ${access_token}`,
            company_id: _id,
        };
        navigate("/", { replace: true, state: { from: location } })
    }

    return (
        <Box
            sx={(theme) => ({
                width: "100%",
                height: "120px",
                backgroundColor: theme.palette.primary.main,
                padding: "20px",
            })}
        >
            <IconButton
                onClick={() => setSidebar((open) => !open)}
                sx={{
                    position: "absolute",
                    display: {sm: "block", md: "none"},
                    zIndex: 50000,
                    right: 0,
                    top: 60,
                }}
            >
                {!sidebar ? (
                    <ArrowBackIosRounded
                        sx={{
                            color: "white",
                        }}
                    />
                ) : (
                    <ArrowForwardIosRounded
                        sx={{
                            color: "white",
                        }}
                    />
                )}
            </IconButton>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                <AnimatedOxygenLogo fill="white" split={1.5}/>
                <Box
                    sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                    }}
                >
                    <Box sx={{position: "relative"}}>
                        <Tooltip title="Multiempresa">
                            <IconButton
                                sx={{background: "rgba(0, 0, 0, 0.13)"}}
                                onClick={(event) => {
                                    setCompany(true);
                                    setAnchorEl(event.currentTarget);
                                }}
                            >
                                <Business
                                    sx={{
                                        color: "white",
                                        width: "20px",
                                        height: "20px",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            open={company}
                            anchorEl={anchorEl}
                            onClose={() => setCompany(false)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            sx={{padding: "10px"}}
                        >
                            <List sx={{maxHeight: 145, overflow: "auto", padding: "5px"}}>
                                {companies_links.map((company) => {
                                    return (
                                        <ListItemButton
                                            sx={{display: "flex", gap: "10px"}}
                                            onClick={(): void => handleCompanyChange(company.company_id)}
                                        >
                                            <Business
                                                sx={{
                                                    color: (theme) => theme.palette.secondary.main,
                                                    width: "20px",
                                                    height: "20px",
                                                }}
                                            />
                                            <Typography color="secondary" fontSize={16}>
                                                {company.company_name}
                                            </Typography>
                                        </ListItemButton>
                                    )
                                })}
                                {/*<ListItemButton sx={{display: "flex", gap: "10px"}}>*/}
                                {/*    <Business*/}
                                {/*        sx={{*/}
                                {/*            color: (theme) => theme.palette.secondary.main,*/}
                                {/*            width: "20px",*/}
                                {/*            height: "20px",*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*    <Typography color="secondary" fontSize={16}>*/}
                                {/*        Empresa 1*/}
                                {/*    </Typography>*/}
                                {/*</ListItemButton>*/}
                                {/*<ListItemButton sx={{display: "flex", gap: "10px"}}>*/}
                                {/*    <Business*/}
                                {/*        sx={{*/}
                                {/*            color: (theme) => theme.palette.secondary.main,*/}
                                {/*            width: "20px",*/}
                                {/*            height: "20px",*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*    <Typography color="secondary" fontSize={16}>*/}
                                {/*        Empresa 1*/}
                                {/*    </Typography>*/}
                                {/*</ListItemButton>*/}
                                {/*<ListItemButton sx={{display: "flex", gap: "10px"}}>*/}
                                {/*    <Business*/}
                                {/*        sx={{*/}
                                {/*            color: (theme) => theme.palette.secondary.main,*/}
                                {/*            width: "20px",*/}
                                {/*            height: "20px",*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*    <Typography color="secondary" fontSize={16}>*/}
                                {/*        Empresa 1*/}
                                {/*    </Typography>*/}
                                {/*</ListItemButton>*/}
                                {/*<ListItemButton sx={{display: "flex", gap: "10px"}}>*/}
                                {/*    <Business*/}
                                {/*        sx={{*/}
                                {/*            color: (theme) => theme.palette.secondary.main,*/}
                                {/*            width: "20px",*/}
                                {/*            height: "20px",*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*    <Typography color="secondary" fontSize={16}>*/}
                                {/*        Empresa 1*/}
                                {/*    </Typography>*/}
                                {/*</ListItemButton>*/}
                                {/*<ListItemButton sx={{display: "flex", gap: "10px"}}>*/}
                                {/*    <Business*/}
                                {/*        sx={{*/}
                                {/*            color: (theme) => theme.palette.secondary.main,*/}
                                {/*            width: "20px",*/}
                                {/*            height: "20px",*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*    <Typography color="secondary" fontSize={16}>*/}
                                {/*        Empresa 1*/}
                                {/*    </Typography>*/}
                                {/*</ListItemButton>*/}
                            </List>
                        </Popover>
                    </Box>

                    <Tooltip title="Notificações">
                        <IconButton sx={{background: "rgba(0, 0, 0, 0.13)"}}>
                            <Notifications
                                sx={{
                                    color: "white",
                                    width: "20px",
                                    height: "20px",
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Sair">
                        <IconButton
                            sx={{background: "rgba(0, 0, 0, 0.13)"}}
                            onClick={logout}
                        >
                            <LogoutRounded
                                sx={{
                                    color: "white",
                                    width: "20px",
                                    height: "20px",
                                    transform: "rotate(180deg)",
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        top: 65,
                        left: 0,
                        right: 0,
                        marginRight: "auto",
                        marginLeft: "auto",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar
                        sx={{
                            fontSize: ".9em",
                            background: background_color ?? "tomato",
                            display: "flex",
                            flexDirection: "column",
                            width: "65px",
                            height: "65px",
                        }}
                    >
                        {name?.charAt(0) + "" + name?.split(/[\W]+/)[1]?.charAt(0)}
                    </Avatar>
                    <Typography
                        textAlign={"center"}
                        fontSize={""}
                        mt={1}
                        fontFamily={"nunito"}
                        fontWeight={700}
                        color={"secondary"}
                    >
                        Olá,{" "}
                        <Typography variant="inherit" color="primary" component={"span"}>
                            {name?.replace(".", " ")}
                        </Typography>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default SidebarUserProfile;

function setSidebar(arg0: (open: any) => boolean): void {
    throw new Error("Function not implemented.");
}
