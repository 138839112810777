import React from "react";
import { BlockProps } from "../../models/Global/types";
import { IProduct } from "../../models/Product/types";
import Block from "../Block";


function ProductBlock({ data }: BlockProps<IProduct>) {
    const [open, setOpen] = React.useState<boolean>(false);

    function handleOpenModal() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    return <>
        <Block data={data} handleOpenModal={setOpen}></Block>
    </>
}

export default ProductBlock;