import axios from "axios";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { IClient } from "../models/Client/types";
import { IError } from "../models/Error/types";
import { IRequestConfig } from "../models/Global/types";

function useClient() {
    const getClients: UseQueryResult<IRequestConfig<IClient> ,IError> = useQuery(
      "client",
      async () => {
        return instance.get("client/get").then((res) => res.data);
      },
      {
        refetchInterval: 60000,
      }
    );

    async function getClientsPage({queryKey}){
      return instance.get(`client/get?page=${queryKey[1]}`).then((res) => res.data);
  }


    const getClient = (client_id: string) => {
      return instance.get(`/client/get/${client_id}`).then((res) => res.data);
    }

    const clientMutation: UseMutationResult<any, IError, any> =
    useMutation(async (values) => {
      return instance.post("/client/store", values).then((res) => res.data);
    });

    const deleteClient = (client: IClient) => {
      return instance.delete(`/client/delete/${client._id}`);
    }

    const clientUpdateMutation: UseMutationResult<any, IError, any> =
    useMutation(async (values) => {
      return instance.put(`/client/update/${values._id}`, values).then((res) => res.data);
    });


    const getAddress = (cep : any) => {
      return axios.get(`https://viacep.com.br/ws/${cep}/json/`).then((value) => value.data, () => false);
    }


  return {
    clientMutation,
    getClients,
    getAddress,
    deleteClient,
    clientUpdateMutation,
    getClient,
    getClientsPage
    };


  }


  export default useClient;