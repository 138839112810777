import { CloseRounded } from "@mui/icons-material";
import { DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography, TextField, FormHelperText } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { Button, FormTextField } from "../../../components";
import Notifier, { INotifierProps } from "../../../components/Notifier/Notifier";
import Yup, { YupUtils } from "../../../configs/yup";
import { IAddProps } from "../../../models/Global/types";
import useUnits from "../../../services/useUnit";
import useClient from "../../../services/useClient";
import usePrescriptions from "../../../services/usePrescription";

function PrescriptionForm({ setAdd, formData }: IAddProps) {
    const { unityMutation, unityUpdateMutation } = useUnits();
    const { prescriptionMutation, prescriptionUpdateMutation } = usePrescriptions();
    const { getClients } = useClient();
    const [notifier, setNotifier] = React.useState<INotifierProps>();
    const initialValues = formData ? formData : {
        company_id: '',
        unity_integration: '',
        bed_integration: '',
        active: true,
        method: 'P',
        // item_01liter: '',
        // item_02liter: '',
        // item_03liter: '',
        // item_04liter: '',
        // item_05liter: '',
        // item_06liter: '',
        // item_07liter: '',
        // item_08liter: '',
        // item_09liter: '',
        // item_10liter: '',
        // item_11liter: '',
        // item_12liter: '',
        // item_13liter: '',
        // item_14liter: '',
        // item_15liter: '',
        // item_16liter: '',
        // item_17liter: '',
        // item_18liter: '',
        // item_19liter: '',
        // item_20liter: '',
    }

    const validations = Yup.object().shape({
        method: Yup.string().required(YupUtils.mesages.REQUIRED_MESAGE),
        company_id: Yup.string().required(YupUtils.mesages.REQUIRED_MESAGE),
    });
    function handleSubmit(values) {

        if (formData) {
            prescriptionUpdateMutation.mutate(values, {
                onSuccess: (response) => {
                    setNotifier({ open: true, msg: "Itens de prescrição Atualizada Com Sucesso!", severity: 'success' });
                    setTimeout(() => setAdd(false), 1000);
                },
                onError: (error) => {
                    setNotifier({ open: true, msg: "Erro ao atualizar itens de prescrição.", severity: 'error' });
                },
                onSettled: () => { },
            });
            return
        }

        prescriptionMutation.mutate(values, {
            onSuccess: (response) => {
                setNotifier({ open: true, msg: "Itens de prescrição cadastrados com sucesso!", severity: 'success' });
                setTimeout(() => setAdd(false)
                    , 1000);
            },
            onError: (error) => {
                setNotifier({ open: true, msg: "Erro ao cadastrar itens de prescrição.", severity: 'error' });
            },
            onSettled: () => { },
        });
    }


    return (
        <>
            <Notifier
                msg={prescriptionMutation.error?.errorMessage || notifier?.msg}
                closeState={setNotifier}
                severity={notifier?.severity}
                open={notifier?.open}
                closeTime={notifier?.closeTime}
            />
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography fontSize={16} fontWeight="bold" color="secondary">
                    Adicionar Prescrição
                </Typography>
                <IconButton onClick={() => setAdd(false)}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validations}
                >
                    {({ setFieldValue, values, errors }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid xs={8} item>
                                    <FormControl fullWidth>
                                        <TextField error={(!!errors.company_id)} name="company_id" label="Cliente" value={values.company_id} onChange={(event) => setFieldValue('company_id', event.target.value.toLocaleString(), true)} select>
                                            {getClients.data?.data.map((option) =>
                                                (<MenuItem value={option._id}>{option.name}</MenuItem>)
                                            )}
                                        </TextField>
                                        <FormHelperText sx={{ color: (theme) => theme.palette.warning.main }}>{errors.company_id}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="method">Método</InputLabel>
                                        <Select name="method" labelId="method" defaultValue={values?.method} onChange={(event) => setFieldValue('method', event.target.value)} label="Método">
                                            <MenuItem value={'P'}>Prescrição</MenuItem>
                                            <MenuItem value={'F'}>Faturamento</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField type="number" name="unity_integration" label="Unidade de Integração" value={values?.unity_integration} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField type="number" name="bed_integration" label="Leito de Integração" value={values?.bed_integration} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="active">Status</InputLabel>
                                        <Select name="active" labelId="active" defaultValue={values.active.toString()} onChange={(event) => setFieldValue('active', JSON.parse(event.target.value))} label="Status">
                                            <MenuItem value={'true'}>Ativo</MenuItem>
                                            <MenuItem value={'false'}>Inativo</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_01liter" label="Item 1" value={values?.item_01liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_02liter" label="Item 2" value={values?.item_02liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_03liter" label="Item 3" value={values?.item_03liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_04liter" label="Item 4" value={values?.item_04liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_05liter" label="Item 5" value={values?.item_05liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_06liter" label="Item 6" value={values?.item_06liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_07liter" label="Item 7" value={values?.item_07liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_08liter" label="Item 8" value={values?.item_08liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_09liter" label="Item 9" value={values?.item_09liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_10liter" label="Item 10" value={values?.item_10liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_11liter" label="Item 11" value={values?.item_11liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_12liter" label="Item 12" value={values?.item_12liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_13liter" label="Item 13" value={values?.item_13liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_14liter" label="Item 14" value={values?.item_14liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_15liter" label="Item 15" value={values?.item_15liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_16liter" label="Item 16" value={values?.item_16liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_17liter" label="Item 17" value={values?.item_17liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_18liter" label="Item 18" value={values?.item_18liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_19liter" label="Item 19" value={values?.item_19liter} ></FormTextField>
                                </Grid>
                                <Grid xs={4} item>
                                    <FormTextField name="item_20liter" label="Item 20" value={values?.item_20liter} ></FormTextField>
                                </Grid>
                                <Grid xs={12} item>
                                    <Button type={"submit"} loading={prescriptionMutation.isLoading}>
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </>
    );
}

export default PrescriptionForm;