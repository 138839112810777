import React, { useContext } from "react";
import { ConstructionOutlined, HomeMiniRounded } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  styled,
  Typography,
} from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { Box } from "@mui/system";
import { IDevice, IDeviceHistoryConfig } from "../../models/MonitoringDevices/types";
import { format, parseISO } from "date-fns";
import { useQuery, UseQueryResult } from "react-query";
import { IError } from "../../models/Error/types";
import Dialog from "../Dialog/Dialog";
import { GlobalContext } from "../../contexts";
import { ptBR } from "date-fns/locale";
import useMonitoringDevices from "../../services/useMonitoringDevices";

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 15,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "& + .MuiSwitch-track": {
      backgroundColor: "#FF4F4F",
      opacity: 1,
      border: 0,
      "&.Mui-disabled": {
        backgroundColor: "#ccc",
      },
    },

    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme.palette.primary.main,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: "#ccc",
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 10,
    height: 10,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface IDeviceProps {
  deviceData: IDevice;
}

function MonitoringDevice({ deviceData }: IDeviceProps) {
  const { findOne } = useMonitoringDevices();
  const { page } = useContext(GlobalContext);
  const [open, setOpen] = React.useState<boolean>(false);
  const deviceRef = React.useRef<any>();
  const getDevice: UseQueryResult<IDeviceHistoryConfig, IError> = useQuery(
    ["newDevice", deviceData.device],
    () => {
      if (deviceData.device) {
        return findOne(deviceData.device, page);
      }
    },
    { enabled: false }
  );

  function handleOpenModal() {
    getDevice.refetch();
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
  deviceRef.current = getDevice.refetch;

  React.useEffect(() => {
    deviceRef.current();
  }, [page]);
  return (
    <>
      <Dialog
        open={open}
        loading={getDevice.isLoading}
        name={deviceData.bed || ""}
        count={
          getDevice.isSuccess && getDevice.data
            ? Math.ceil(getDevice.data.total / getDevice.data.limit)
            : 0
        }
        handleClose={handleClose}
        history={getDevice.data?.data || []}
      />
      <Card
        sx={{
          maxWidth: { xs: "100%", md: "250px" },
          minWidth: { xs: "100%", md: "250px" },
          width: "100%",
          "&:hover": {
            boxShadow: 3,
            cursor: deviceData.date ? "pointer" : "default",
          },
        }}
        onClick={() => {
          if (deviceData.date) {
            handleOpenModal();
          }
        }}
      >
        <CardActions>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              margin: "0px 5px",
              alignItems: "center",
            }}
          >
            <HomeMiniRounded color="secondary" />
            {deviceData.online ? (
              <IOSSwitch defaultChecked={false} checked={deviceData.status} />
            ) : (
              <IOSSwitch disabled />
            )}
          </Box>
        </CardActions>
        <CardContent>
          {/* <Typography variant="inherit" color={`secondary`} component="span">
          <HotelRounded />
        </Typography> */}
          <Typography fontWeight={700} color="secondary" fontSize={"2em"}>
            {deviceData.bed.toUpperCase()}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            backgroundColor: "rgba(0, 204, 170, 0.05)",
          }}
        >
          {deviceData.date ? (
            <Typography
              fontWeight={"bold"}
              fontSize={".6em"}
              color={"secondary"}
            >
              ÚLTIMA AÇÃO :{" "}
              <Typography variant="inherit" color="primary" component={"span"}>
                {deviceData.date
                  ? format(parseISO(deviceData.date), "dd/MM/yyyy")
                  : ""}
              </Typography>{" "}
              ÀS{" "}
              <Typography variant="inherit" color="primary" component={"span"}>
                {deviceData.date
                  ? format(parseISO(deviceData.date), "HH:mm", { locale: ptBR })
                  : ""}
              </Typography>
            </Typography>
          ) : deviceData.device ? (
            <Typography
              fontWeight={"bold"}
              fontSize={".5em"}
              color={"secondary"}
            >
              LEITO FALTANDO CONFIGURAR DISPOSITIVO
            </Typography>
          ) : (
            <Typography
              fontWeight={"bold"}
              fontSize={".5em"}
              color={"secondary"}
            >
              LEITO NÃO POSSUI DISPOSITIVOS VINCULADOS
            </Typography>
          )}
        </CardActions>
      </Card>
    </>
  );
}

export default MonitoringDevice;
