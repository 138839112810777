import axios from "axios";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { IError } from "../models/Error/types";
import { IRequestConfig } from "../models/Global/types";
import { IProduct } from "../models/Product/types";

function useProduct() {
    const getProducts: UseQueryResult<IRequestConfig<IProduct>, IError> = useQuery(
        "product",
        async () => {
            return instance.get("product/get").then((res) => res.data);
        },
        {
            refetchInterval: 60000,
        }
    );

    async function getProductsPage({queryKey}){
        return instance.get(`product/get?page=${queryKey[1]}`).then((res) => res.data);
      }

    const getProduct = (product_id: string) => {
        return instance.get(`/product/get/${product_id}`).then((res) => res.data);
    }

    const productMutation: UseMutationResult<any, IError, any> =
        useMutation(async (values) => {
            return instance.post("/product/store", values).then((res) => res.data);
        });

    const deleteProduct = (product: IProduct) => {
        return instance.delete(`/product/delete/${product._id}`);
    }

    const productUpdateMutation: UseMutationResult<any, IError, any> =
        useMutation(async (values) => {
            return instance.put(`/product/update/${values._id}`, values).then((res) => res.data);
        });

    return {
        productMutation,
        getProducts,
        deleteProduct,
        productUpdateMutation,
        getProduct,
        getProductsPage
    };


}


export default useProduct;