import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import Block from "../../../components/Block";
import BlockWard from "../../../components/BlockWard";
import ClientBlock from "../../../components/Client/ClientBlock";
import ClientListWard from "../../../components/Client/ClientListWard";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import { INotifierProps } from "../../../components/Notifier/Notifier";
import ListWard from "../../../components/Ward/ListWard";
import { GlobalContext } from "../../../contexts";
import { IClient } from "../../../models/Client/types";
import useClient from "../../../services/useClient";
import { Notifier } from "../../../components";
import { useQuery } from "react-query";

function Clients() {

  const { view } = useContext(GlobalContext);
  const [notifier, setNotifier] = useState<INotifierProps>(null);
  const [page, setPage] = useState(1);
  const { deleteClient, getClientsPage } = useClient()
  const [openDelete, setOpenDelete] = React.useState<{ open: boolean, valueToDelete: any }>({ open: false, valueToDelete: null });

  const currents = useQuery(['clients', page], getClientsPage, {
    refetchInterval: 60000,
  })

  function handleCloseDelete(): void {
    setOpenDelete({ open: false, valueToDelete: null });
  }
  function handlePaginator(page: number) {
    setPage(page)
  }

  useEffect(() => {
    currents.refetch();
  }, [page])

  function handleDelete() {
    deleteClient(openDelete.valueToDelete).then(
      () => setNotifier({ open: true, severity: 'success', msg: 'Cadastro deletado com sucesso!' }),
      () => setNotifier({ open: true, severity: 'error', msg: 'Erro ao deletar cadastro.' }),
    );
    handleCloseDelete();
  }


  if (currents.isSuccess && currents.data.data.length) {

    if (view === "block")
      return (
        <Grid
          container
          gap={2}
          padding={view === "block" ? "20px" : ""}
          sx={{
            overflowY: "auto",
          }}
        >
          <>

            <BlockWard title="Clientes" _id="clientes">
              {currents.data.data.map((item) => (
                <ClientBlock data={item} key={item._id} />
              ))}
            </BlockWard>
          </>
        </Grid>
      );
    else return <>
      <Notifier
        msg={notifier?.msg}
        closeState={setNotifier}
        severity={notifier?.severity}
        open={notifier?.open}
        closeTime={notifier?.closeTime}
      />
      <ClientListWard isPageable={true} setDelete={setOpenDelete} limit={currents.data.limit} total={currents.data.total} handlePaginator={handlePaginator} data={currents.data.data}></ClientListWard>;
      <DeleteDialog open={openDelete.open} handleClose={handleCloseDelete} onConfirmDelete={handleDelete} name="deleteDialog" ></DeleteDialog>
    </>
  }
  return null;
}

export default Clients;