import {useQuery} from "react-query";
import instance from "../configs/api";

export const useDashboard = () => {
    return useQuery(
        "dashboards",
        () =>
            instance.get("dashboard/consumption_lpm?range=30").then((res) => res.data),
        {
            refetchInterval: 60000,
        }
    );
};
