import {AnalyticsRounded, SettingsRounded} from "@mui/icons-material";
import HomeMiniIcon from "@mui/icons-material/HomeMini";

interface ISideItems {
    name: string;
    to: string;
    icon: (active: boolean) => React.ReactElement;
    children?: ISideItems[];
    permission?: string;
}

const items: ISideItems[] = [
    {
        permission: 'client',
        name: "Meus dispositivos",
        to: "/monitoring-device",
        icon: (active) => (
            <HomeMiniIcon
                sx={(theme) => ({
                    color: active
                        ? theme.palette.primary.main
                        : theme.palette.secondary.light,
                })}
            />
        ),
    },
    {
        name: "Meus Relatórios",
        to: "/reports",
        icon: (active) => (
            <AnalyticsRounded
                sx={(theme) => ({
                    color: active
                        ? theme.palette.primary.main
                        : theme.palette.secondary.light,
                })}
            />
        ),
    },
    {
        permission: 'iottic',
        name: "Cadastro",
        to: "#",
        icon: (active) => (
            <AnalyticsRounded
                sx={(theme) => ({
                    color: active
                        ? theme.palette.primary.main
                        : theme.palette.secondary.light,
                })}
            />
        ),
        children: [{
            name: "Clientes",
            to: "/clients",
            icon: (active) => (
                <AnalyticsRounded
                    sx={(theme) => ({
                        color: active
                            ? theme.palette.primary.main
                            : theme.palette.secondary.light,
                    })}
                />
            ),
        },
            {
                name: "Usuários",
                to: "/users",
                icon: (active) => (
                    <AnalyticsRounded
                        sx={(theme) => ({
                            color: active
                                ? theme.palette.primary.main
                                : theme.palette.secondary.light,
                        })}
                    />
                ),
            },
            {
                name: "Produtos",
                to: "/products",
                icon: (active) => (
                    <AnalyticsRounded
                        sx={(theme) => ({
                            color: active
                                ? theme.palette.primary.main
                                : theme.palette.secondary.light,
                        })}
                    />
                ),
            },
            {
                name: "Dispositivos",
                to: "/devices",
                icon: (active) => (
                    <AnalyticsRounded
                        sx={(theme) => ({
                            color: active
                                ? theme.palette.primary.main
                                : theme.palette.secondary.light,
                        })}
                    />
                ),
            },
            {
                name: "Unidades",
                to: "/units",
                icon: (active) => (
                    <AnalyticsRounded
                        sx={(theme) => ({
                            color: active
                                ? theme.palette.primary.main
                                : theme.palette.secondary.light,
                        })}
                    />
                ),
            },
            {
                name: "Itens De Prescrições",
                to: "/prescription-itens",
                icon: (active) => (
                    <AnalyticsRounded
                        sx={(theme) => ({
                            color: active
                                ? theme.palette.primary.main
                                : theme.palette.secondary.light,
                        })}
                    />
                ),
            }
        ],
    },
    {
        permission: 'client',
        name: "Configurações",
        to: "/settings",
        icon: (active) => (
            <SettingsRounded
                sx={(theme) => ({
                    color: active
                        ? theme.palette.primary.main
                        : theme.palette.secondary.light,
                })}
            />
        ),
    },
];

export {items};
