import * as Yup from "yup";
//Yup custom validations.
export default Yup;

export const YupUtils = {
    mesages : {
        REQUIRED_MESAGE : 'É requerido',
        EMAIL_INVALID_MESAGE: 'E-mail inválido',
        COLOR_INVALID_MESAGE: 'Cor inválida Ex.: #FFF',
    },
    regexp:{
        EMAIL_REGEXP: new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/),
        COLOR_REGEXP: new RegExp(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
    }
}