import React, {useContext} from "react";
import {Box, Typography} from "@mui/material";
import {motion} from "framer-motion";
import useMonitoringDevices from "../../services/useMonitoringDevices";
import {useQuery, UseQueryResult} from "react-query";
import {IError} from "../../models/Error/types";
import {IDevice} from "../../models/MonitoringDevices/types";
import {Navigate} from "react-router-dom";
import {AuthContext} from "../../contexts";
import {useLocalStorage} from "../../hooks";
import {ILoggedUser} from "../../models/Auth/types";

const variants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.3,
            duration: 0.3,
        },
    },
};

function Home() {
    const AnimatedBox = motion(Box);
    let {access_token, roles} = useContext(AuthContext);
    const {get} = useLocalStorage<ILoggedUser>();
    const {access_token: local_access_token, roles: local_roles} = get('user')

    if (!roles) {
        roles = local_roles
    }

    return (
        <AnimatedBox initial="hidden" variants={variants} animate="visible">
            {
                (roles?.find((roleToFind) => (roleToFind === 'iottic' || roleToFind === 'super') ?? roleToFind)) ?
                    <Navigate to={"/clients"}/> :
                    <Navigate to={"/monitoring-device"}/>
            }
        </AnimatedBox>
    );
}

export default Home;
