import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CloseRounded, Password } from "@mui/icons-material";
import Yup from "../../../configs/yup";
import { IUnityProps } from "../../../components/Sidebar/Add/types";
import { Button, FormTextField, Notifier } from "../../../components";
import { Form, Formik } from "formik";
import { useLocalStorage } from "../../../hooks";
import useBed from "../../../services/useBed";
import useClient from "../../../services/useClient";
import { IAddress, IClient } from "../../../models/Client/types";
import { IAddProps } from "../../../models/Global/types";
import { INotifierProps } from "../../../components/Notifier/Notifier";

function ClientForm({ setAdd, formData }: IAddProps) {
  const { get } = useLocalStorage();
  const { clientMutation, getAddress, clientUpdateMutation } = useClient();
  const [notifier, setNotifier] = React.useState<INotifierProps>(null);
  const [address, setAddress] = React.useState<IAddress | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const initialValues = formData ? formData : {
    name: "",
    fiscal_number: "",
    ssid: "",
    password: "",
    responsible: "",
    active: true,
    integration_user: "",
    integration_password: "",
    integration_host: "",
    time_zone: "",
    address: {
      city: '',
      district: '',
      postal_code: '',
      state: '',
      street: '',
      geolocation: null,
      number: ''
    },
  };

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
    fiscal_number: Yup.string().required("É requerido").matches(new RegExp(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/), 'CNPJ inválido'),
    ssid: Yup.string().required("É requerido"),
    password: Yup.string().required("É requerido"),
    // password_confirm:  Yup.string()
    // .oneOf([Yup.ref('password')], 'Passwords are not the same!')
    // .required('Password confirmation is required!'),
    responsible: Yup.string().required("É requerido"),
    time_zone: Yup.string().required("É requerido").matches(new RegExp(/^[A-Z]\w*\/[A-Z]\w*$/), 'Zona temporal inválida'),
    address: Yup.object().shape({
      city: Yup.string().required("É requerido"),
      district: Yup.string().required("É requerido"),
      postal_code: Yup.string().required("É requerido"),
      state: Yup.string().required("É requerido"),
      street: Yup.string().required("É requerido"),
    })
  });
  function handleSubmit(values: IClient) {

    if (formData) {
      clientUpdateMutation.mutate(values, {
        onSuccess: (response) => {
          setNotifier({ open: true, msg: "Cliente Atualizado Com Sucesso!", severity: 'success' });
          setTimeout(() => setAdd(false), 1000);

        },
        onError: (error) => {
          setNotifier({ open: true, msg: "Erro ao atualizar usuário.", severity: 'error' });
        },
        onSettled: () => { },
      });
      return
    }

    clientMutation.mutate(values, {
      onSuccess: (response) => {
        setNotifier({ open: true, msg: "Cliente Atualizado Com Sucesso!", severity: 'success' });
        setTimeout(() => setAdd(false), 1000);
      },
      onError: (error) => {
        setNotifier({ open: true, msg: "Erro ao cadastrar cliente.", severity: 'error' });
      },
      onSettled: () => { },
    });
  }

  interface GetAddressByCepProps {
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  }

  async function getAddressByCep({ event, setFieldValue }: GetAddressByCepProps) {
    const address = await getAddress(event.currentTarget.value);
    if (!address) {
      setFieldValue('address', initialValues.address);
      return
    }
    setFieldValue('address', {
      city: address.localidade,
      district: address.bairro,
      postal_code: address.cep,
      state: address.uf,
      street: address.logradouro,
      geolocation: null,
      number: 'S/N'
    })
  }

  return (
    <>
      <Notifier
        msg={clientMutation.error?.errorMessage || notifier?.msg}
        closeState={setNotifier}
        severity={notifier?.severity}
        open={notifier?.open}
        closeTime={notifier?.closeTime}
      />
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={16} fontWeight="bold" color="secondary">
          Adicionar Cliente
        </Typography>
        <IconButton onClick={() => setAdd(false)}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validations}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <FormTextField name="name" label="Nome" value={values?.name} ></FormTextField>
                </Grid>
                <Grid xs={12} item>
                  <FormTextField name="fiscal_number" value={values?.fiscal_number} label="CNPJ"></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormTextField name="ssid" label="SSID" value={values?.ssid} ></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormTextField name="responsible" value={values?.responsible} label="Nome do reponsável"></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormTextField name="integration_user" value={values?.integration_user} label="Usuario de integração"></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormTextField name="integration_password" value={values?.integration_password} label="Senha do Usuario de integração"></FormTextField>
                </Grid>
                <Grid xs={12} item>
                  <FormTextField name="integration_host" value={values?.integration_host} label="Host de integração"></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormTextField name="time_zone" value={values?.time_zone} label="Zona temporal"></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormControl fullWidth>
                    <InputLabel id="active">Status</InputLabel>
                    <Select name="active" labelId="active" defaultValue={values.active.toString()} onChange={(event) => setFieldValue('active', JSON.parse(event.target.value))} label="Status">
                      <MenuItem value={'true'}>Ativo</MenuItem>
                      <MenuItem value={'false'}>Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} item>
                  <FormTextField name="password" type="password" value={values?.password} label="Senha do cliente"></FormTextField>
                </Grid>
                {/* <Grid xs={6} item>
                  <FormTextField name="password_confirm" type="password" label="Confirme a senha"></FormTextField>
                </Grid> */}
                <Grid xs={12} item textAlign={'center'}>
                  Endereço
                </Grid>
                <Grid xs={8} item>
                  <FormTextField name="address.street" label="Rua" value={values?.address?.street} disabled></FormTextField>
                </Grid>
                <Grid xs={4} item>
                  <FormTextField name="address.number" label="Numero" value={values?.address?.number} disabled={(values.address?.postal_code === '')}></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormTextField name="address.district" label="Bairro" value={values?.address?.district} disabled></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormTextField name="address.city" label="Cidade" value={values?.address?.city} disabled></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormTextField name="address.state" label="Estado" value={values?.address?.state} disabled></FormTextField>
                </Grid>
                <Grid xs={6} item>
                  <FormTextField name="address.postal_code" value={values?.address?.postal_code} onBlur={(event) => getAddressByCep({ event, setFieldValue })} label="CEP"></FormTextField>
                </Grid>
                <Grid xs={12} item>
                  <Button type={"submit"} loading={clientMutation.isLoading}>
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  );
}

export default ClientForm;
