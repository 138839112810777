import React from "react";
import {
    Box,
    Dialog as MuiDialog,
    DialogActions,
    Pagination,
    Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {IDeviceHistory} from "../../models/MonitoringDevices/types";
import {HomeMiniRounded} from "@mui/icons-material";
import {format, parseISO} from "date-fns";
import {GlobalContext} from "../../contexts";
import {AnimatedOxygenLogo} from "../../helpers";

interface IDialog {
    open: boolean;
    history: IDeviceHistory[];
    name: string;
    handleClose: () => void;
    count: number;
    loading: boolean;
}

function Dialog({open, handleClose, history, loading, count, name}: IDialog) {
    const {page, setPage} = React.useContext(GlobalContext);
    return (
        <MuiDialog
            open={open}
            onClose={handleClose}
            sx={{
                "& .MuiDialogContent-root": {
                    padding: "16px 0px",
                },
            }}
        >
            <DialogTitle title="Teste">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                    }}
                >
                    <HomeMiniRounded color="primary"/>
                    <Typography color="primary" fontWeight={"bold"}>
                        {name?.toUpperCase()}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            padding: "10px",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography color="secondary.light" fontWeight="bold" sx={{minWidth: '12rem'}}>
                            Data do Ocorrido
                        </Typography>
                        <Typography color="secondary.light" fontWeight="bold" sx={{minWidth: '4rem'}} align={'center'}>
                            L / M
                        </Typography>
                        <Typography color="secondary.light" fontWeight="bold" sx={{minWidth: '7rem'}}>
                            Status
                        </Typography>
                    </Box>
                    {!loading && history && history.length ? (
                        history.map((item, key) => (
                            <Box
                                key={key}
                                sx={{
                                    backgroundColor:
                                        key % 2 === 0 ? "white" : "rgba(0, 204, 170, 0.1)",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        textTransform: "uppercase",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                        padding: "10px",
                                    }}
                                >
                                    <Typography color="secondary.light" fontWeight="bold" sx={{minWidth: '12rem'}}>
                                        {item.date
                                            ? format(parseISO(item.date), "dd/MM/yyyy à's' HH:mm")
                                            : ""}
                                    </Typography>
                                    <Typography
                                        color={item.status ? "secondary" : "red"}
                                        fontWeight="bold"
                                        align={'center'}
                                        sx={{minWidth: '4rem'}}
                                    >
                                        {item.gauge}
                                    </Typography>
                                    <Typography
                                        color={item.status ? "secondary" : "red"}
                                        fontWeight="bold"
                                        sx={{minWidth: '7rem'}}
                                    >
                                        {item.status ? "Ligado" : "Desligado"}
                                    </Typography>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <AnimatedOxygenLogo repeat/>
                    )}
                </Box>
                <DialogActions>
                    <Pagination
                        count={count}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                    />
                </DialogActions>
            </DialogContent>
        </MuiDialog>
    );
}

export default Dialog;
