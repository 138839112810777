import React from "react";
import {
    Box,
    Dialog as MuiDialog,
    DialogActions,
    Pagination,
    Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IDeviceHistory } from "../../models/MonitoringDevices/types";
import { HomeMiniRounded } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { GlobalContext } from "../../contexts";
import { IDialogInfo } from "../../models/Global/types";
import InfoLine from "../InfoLine";
import { IUnit } from "../../models/Unit/types";

function UnityInfo({ open, handleClose, data }: IDialogInfo<IUnit>) {
    return (
        <MuiDialog
            open={open}
            onClose={handleClose}
            sx={{
                "& .MuiDialogContent-root": {
                    padding: "16px 0px",

                },
                "& .MuiPaper-root": {
                    maxWidth: '70vw'
                }
            }}
        >
            <DialogTitle title="Cliente">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        padding: "0 50px"
                    }}
                >
                    <HomeMiniRounded color="primary" />
                    <Typography color="primary" fontWeight={"bold"}>
                        {data.unity.toUpperCase()}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        gap: "2px",
                    }}
                >
                    <InfoLine label="Nome" info={data?.unity}></InfoLine>
                    <InfoLine label="Leito" info={data?.bed}></InfoLine>
                    <InfoLine label="Cliente" info={data?.company_id}></InfoLine>
                    <InfoLine label="Unidade de Integração" info={data?.unity_integration}></InfoLine>
                    <InfoLine label="Leito de integração" info={data?.bed_integration}></InfoLine>
                    <InfoLine label="Status" info={data?.active ? 'Ativo' : 'inativo'}></InfoLine>
                </Box>
            </DialogContent>
        </MuiDialog>
    );
}

export default UnityInfo;
