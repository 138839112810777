import React from "react";
import { BlockProps } from "../../models/Global/types";
import { IUnit } from "../../models/Unit/types";
import Block from "../Block";
import UnityInfo from "./UnityInfo";

function UnityBlock({ data }: BlockProps<IUnit>) {
    const [open, setOpen] = React.useState<boolean>(false);

    function handleOpenModal() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    return <>
        <UnityInfo
            open={open}
            // loading={getDevice.isLoading}
            data={data}
            handleClose={handleClose}
        />
        <Block data={data} label={'unity'} handleOpenModal={setOpen}></Block>

    </>
}

export default UnityBlock;