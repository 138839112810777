import axios from "axios";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { IError } from "../models/Error/types";
import { IRequestConfig } from "../models/Global/types";
import { Device } from "../models/Device/types";

function useDevice() {
    const getDevices: UseQueryResult<IRequestConfig<Device>, IError> = useQuery(
        "device",
        async () => {
            return instance.get("device/get")
                .then((res) => res.data);
        },
        {
            refetchInterval: 60000,
        }
    );

    async function getDevicesPage({queryKey}){
        return instance.get(`device/get?page=${queryKey[1]}`).then((res) => res.data);
    }


    const deviceMutation: UseMutationResult<any, IError, any> =
        useMutation(async (values) => {
            return instance.post("/device/store", values).then((res) => res.data);
        });

    const deleteDevice = (device: Device) => {
        return instance.delete(`/device/delete/${device._id}`);
    }

    const deviceUpdateMutation: UseMutationResult<any, IError, any> =
        useMutation(async (values) => {
            return instance.put(`/device/update/${values._id}`, values).then((res) => res.data);
        });

    return {
        deviceMutation,
        getDevices,
        deleteDevice,
        deviceUpdateMutation,
        getDevicesPage
    };


}


export default useDevice;