import {useMediaQuery} from "@mui/material";
import React, {createContext} from "react";
import {useQuery, UseQueryResult} from "react-query";
import {IActives} from "../models/MonitoringDevices/types";
import {IError} from "../models/Error/types";
import {CompanyEntity, IGlobalContext, IGlobalProvider} from "../models/Global/types";

export const GlobalContext = createContext<IGlobalContext>(
    {} as IGlobalContext
);
export const GlobalProvider = ({children}: IGlobalProvider) => {
    const [page, setPage] = React.useState<number>(1);
    const [view, setView] = React.useState<"list" | "block">("block");
    const [add, setAdd] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<any>(undefined);
    const [sidebar, setSidebar] = React.useState<boolean>(false);
    const [companies, setCompanies] = React.useState<any>(undefined);
    const matches = useMediaQuery("(min-width:900px)");
    React.useEffect(() => {
        if (matches) {
            setSidebar(true);
        }
    }, [matches]);
    React.useEffect(() => {
        if (!add) {
            setFormData(null);
        }
    }, [add]);
    React.useEffect(() => {
    if (!add) {
      setFormData(null);
    }
  }, [add]);return (
        <GlobalContext.Provider
            value={{
                formData,
                setFormData,
                add,
                setAdd,
                page,
                setPage,
                view,
                setView,
                sidebar,
                setSidebar,
                companies,
                setCompanies,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
