import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";

import { IError } from "../models/Error/types";
import { IRequestConfig } from "../models/Global/types";
import { IUser } from "../models/User/types";

function useUser() {
    const getUsers: UseQueryResult<IRequestConfig<IUser> ,IError> = useQuery(
      "user",
      async (values) => {
        return instance.get("user/get",values).then((res) => res.data);
      },
      {
        refetchInterval: 60000,
      }
    );

    async function getUsersPage({queryKey}){
      return instance.get(`user/get?page=${queryKey[1]}`).then((res) => res.data);
    }

    const userMutation: UseMutationResult<any, IError, any> =
    useMutation(async (values) => {
      return instance.post("/user/store", values).then((res) => res.data);
    });

    const deleteUser = (user: IUser) => {
      return instance.delete(`/user/delete/${user._id}`);
    }

    const userUpdateMutation: UseMutationResult<any, IError, any> =
    useMutation(async (values) => {
      return instance.put(`/user/update/${values._id}`, values).then((res) => res.data);
    });

    const userUpdatePasswordMutation: UseMutationResult<any, IError, any> =
    useMutation(async (values) => {
      return instance.put(`/user/update/${values._id}/password`, values).then((res) => res.data);
    });


  return {
    userMutation,
    getUsers,
    deleteUser,
    userUpdateMutation,
    userUpdatePasswordMutation,
    getUsersPage
    };


  }


  export default useUser;