import React from "react";
import {
  Grid,
  IconButton,
  ListItem,
  Popover,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Dialog,
} from "@mui/material";
import { IWardBlockProps } from "./types";
import { grey } from "@mui/material/colors";
import MonitoringDevice from "../MonitoringDevice/MonitoringDevice";
import {
  AddRounded,
  LinkRounded,
  More,
  MoreHorizRounded,
} from "@mui/icons-material";
import AddDevice from "../AddDevice/AddDevice";
function BlockWard({ title, _id, data }: IWardBlockProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [more, setMore] = React.useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose(): void {
    setOpen(false);
  }
  return (
    <>
      {/* <Dialog open={open} onClose={handleClose}>
        <AddDevice setOpen={setOpen} />
      </Dialog> */}
      <Grid
        item
        xs={12}
        container
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: grey[50],
          padding: "10px",
          borderRadius: "5px",
          border: `1px solid ${grey[200]}`,
          gap: "10px",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            fontFamily={"Nunito"}
            fontWeight={"bold"}
            color="primary"
            fontSize={".825em"}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            {/* <Typography variant="inherit" color={`secondary`} component="span">
          <HotelRounded />
        </Typography> */}
            {title}
          </Typography>
          {/* <IconButton
            onClick={(event) => {
              setMore(true);
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreHorizRounded />
          </IconButton>{" "} */}
          {/* <Popover
            open={more}
            anchorEl={anchorEl}
            onClose={() => setMore(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{ padding: "10px" }}
          >
            <List
              sx={{
                maxWidth: 230,
              }}
            >
              <ListItem disablePadding>
                <ListItemButton onClick={(): void => setOpen(true)}>
                  <ListItemIcon>
                    <LinkRounded
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                    ></LinkRounded>
                  </ListItemIcon>
                  <ListItemText
                    primary="Vincular Dispositivo"
                    primaryTypographyProps={{
                      fontSize: 12,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Popover> */}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
        >
          {data.map((item) => (
            <MonitoringDevice deviceData={item} key={item._id} />
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default BlockWard;
