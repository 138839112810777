import { HomeMiniRounded } from "@mui/icons-material";
import { Box, Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
interface IDialog {
    open: boolean;
    name: string;
    handleClose: () => void;
    onConfirmDelete: () => void;
  }
function DeleteDialog({ open, handleClose, name , onConfirmDelete }: IDialog) {
    return <MuiDialog
    open={open}
    onClose={handleClose}
    sx={{
      "& .MuiDialogContent-root": {
        padding: "16px 0px",
      },
    }}
  >
    <DialogTitle title="Teste">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent:"center",
            gap: "15px",
          }}
        >
          <Typography color="primary" fontWeight={"bold"}>
            Deletar registro ?
          </Typography>
        </Box>
      </DialogTitle>
    <DialogContent dividers>
    <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding:'10px',
            gap: "15px",
          }}
        >
            {`Você deseja excluir o registro ?` }
        </Box>
      <DialogActions>
      <Box
          sx={{
            width: '-webkit-fill-available',
            display: "flex",
            justifyContent:'space-between',
            alignItems: "center",
          }}
        >
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={onConfirmDelete}>Confirmar</Button>
            </Box>
      </DialogActions>
    </DialogContent>
  </MuiDialog>
}

export default DeleteDialog;