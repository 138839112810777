import React from "react";
import { IClient } from "../../models/Client/types";
import { BlockProps } from "../../models/Global/types";
import Block from "../Block";
import ClientInfo from "./ClientInfo";


function ClientBlock({ data }: BlockProps<IClient>) {
  const [open, setOpen] = React.useState<boolean>(false);

  function handleOpenModal() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
  return <>
    <ClientInfo
      open={open}
      // loading={getDevice.isLoading}
      data={data}
      handleClose={handleClose}
    />
    <Block data={data} handleOpenModal={setOpen}></Block>

  </>
}

export default ClientBlock;