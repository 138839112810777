import { CloseRounded } from "@mui/icons-material";
import { DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { Button, FormTextField } from "../../../components";
import Notifier, { INotifierProps } from "../../../components/Notifier/Notifier";
import Yup, { YupUtils } from "../../../configs/yup";
import { IAddProps } from "../../../models/Global/types";
import useProduct from "../../../services/useProduct";

function ProductsForm({ setAdd, formData }: IAddProps) {
    const { productMutation, productUpdateMutation, getProducts } = useProduct();
    const [notifier, setNotifier] = React.useState<INotifierProps>();
    const initialValues = formData ? formData : {
        name: '',
        active: true
    }

    const validations = Yup.object().shape({
        name: Yup.string().required(YupUtils.mesages.REQUIRED_MESAGE),
    });
    function handleSubmit(values) {

        if (formData) {
            productUpdateMutation.mutate(values, {
                onSuccess: (response) => {
                    setNotifier({ open: true, msg: "Produto Atualizado Com Sucesso!", severity: 'success' });
                    setTimeout(() => setAdd(false), 1000);
                },
                onError: (error) => {
                    setNotifier({ open: true, msg: "Erro ao atualizar Produto.", severity: 'error' });
                },
                onSettled: () => { },
            });
            return
        }

        productMutation.mutate(values, {
            onSuccess: (response) => {
                setNotifier({ open: true, msg: "Produto Cadastrado Com Sucesso!", severity: 'success' });
                setTimeout(() => setAdd(false)
                    , 1000);
            },
            onError: (error) => {
                setNotifier({ open: true, msg: "Erro ao cadastrar Produto.", severity: 'error' });
            },
            onSettled: () => { },
        });
    }


    return (
        <>
            <Notifier
                msg={productMutation.error?.errorMessage || notifier?.msg}
                closeState={setNotifier}
                severity={notifier?.severity}
                open={notifier?.open}
                closeTime={notifier?.closeTime}
            />
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography fontSize={16} fontWeight="bold" color="secondary">
                    Adicionar Produto
                </Typography>
                <IconButton onClick={() => setAdd(false)}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validations}
                >
                    {({ setFieldValue, values }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid xs={12} item>
                                    <FormTextField name="name" label="Nome" value={values?.name} ></FormTextField>
                                </Grid>
                                <Grid xs={12} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="active">Status</InputLabel>
                                        <Select name="active" labelId="active" defaultValue={values.active.toString()} onChange={(event) => setFieldValue('active', JSON.parse(event.target.value))} label="Status">
                                            <MenuItem value={'true'}>Ativo</MenuItem>
                                            <MenuItem value={'false'}>Inativo</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} item>
                                    <Button type={"submit"} loading={productMutation.isLoading}>
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </>
    );
}

export default ProductsForm;