import React from "react";
import {
    Box,
    Dialog as MuiDialog,
    DialogActions,
    Pagination,
    Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IDeviceHistory } from "../../models/MonitoringDevices/types";
import { HomeMiniRounded } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { GlobalContext } from "../../contexts";
import { IDialogInfo } from "../../models/Global/types";
import InfoLine from "../InfoLine";
import {IPrescription} from "../../models/Prescription/types";

function PrescriptionInfo({ open, handleClose, data }: IDialogInfo<IPrescription>) {
    return (
        <MuiDialog
            open={open}
            onClose={handleClose}
            sx={{
                "& .MuiDialogContent-root": {
                    padding: "16px 0px",

                },
                "& .MuiPaper-root": {
                    maxWidth: '70vw'
                }
            }}
        >
            <DialogTitle title="Prescription">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        padding: "0 50px"
                    }}
                >
                    <HomeMiniRounded color="primary" />
                    <Typography color="primary" fontWeight={"bold"}>
                        {data?._id}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        gap: "2px",
                    }}
                >
                    <InfoLine label="Unidade de Integração" info={data?.unity_integration}></InfoLine>
                    <InfoLine label="Leito de integração" info={data?.bed_integration}></InfoLine>
                    <InfoLine label="Cliente" info={data?.company_id}></InfoLine>
                    <InfoLine label="Método" info={data?.method}></InfoLine>
                    <InfoLine label="Status" info={data?.active ? 'Ativo' : 'inativo'}></InfoLine>
                    <InfoLine label="Item 1" info={data?.item_01liter}></InfoLine>
                    <InfoLine label="Item 2" info={data?.item_02liter}></InfoLine>
                    <InfoLine label="Item 3" info={data?.item_03liter}></InfoLine>
                    <InfoLine label="Item 4" info={data?.item_04liter}></InfoLine>
                    <InfoLine label="Item 5" info={data?.item_05liter}></InfoLine>
                    <InfoLine label="Item 6" info={data?.item_06liter}></InfoLine>
                    <InfoLine label="Item 7" info={data?.item_07liter}></InfoLine>
                    <InfoLine label="Item 8" info={data?.item_08liter}></InfoLine>
                    <InfoLine label="Item 9" info={data?.item_09liter}></InfoLine>
                    <InfoLine label="Item 10" info={data?.item_10liter}></InfoLine>
                    <InfoLine label="Item 11" info={data?.item_11liter}></InfoLine>
                    <InfoLine label="Item 12" info={data?.item_12liter}></InfoLine>
                    <InfoLine label="Item 13" info={data?.item_13liter}></InfoLine>
                    <InfoLine label="Item 14" info={data?.item_14liter}></InfoLine>
                    <InfoLine label="Item 15" info={data?.item_15liter}></InfoLine>
                    <InfoLine label="Item 16" info={data?.item_16liter}></InfoLine>
                    <InfoLine label="Item 17" info={data?.item_17liter}></InfoLine>
                    <InfoLine label="Item 18" info={data?.item_18liter}></InfoLine>
                    <InfoLine label="Item 19" info={data?.item_19liter}></InfoLine>
                    <InfoLine label="Item 20" info={data?.item_20liter}></InfoLine>
                </Box>
            </DialogContent>
        </MuiDialog>
    );
}

export default PrescriptionInfo;
