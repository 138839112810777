import styled from "@emotion/styled";
import {HomeMiniRounded} from "@mui/icons-material";
import {Card, CardActions, CardContent, Switch, SwitchProps, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {format} from "date-fns";
import {parseISO} from "date-fns";
import {ptBR} from "date-fns/locale";
import React from "react";
import {BlockProps} from "../../models/Global/types";
import {IOSSwitch} from "../MonitoringDevice/MonitoringDevice";

function Block({data, handleOpenModal, label}: BlockProps<any>) {
    return <>
        <Card
            sx={{
                maxWidth: {xs: "100%", md: "300px"},
                minWidth: {xs: "100%", md: "300px"},
                width: "100%",
                "&:hover": {
                    boxShadow: 3,
                    cursor: data.active ? "pointer" : "default",
                },
            }}
            onClick={() => {
                if (data.active) {
                    handleOpenModal(true);
                }
            }}
        >
            <CardActions>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        margin: "0px 5px",
                        alignItems: "center",
                    }}
                >
                    <HomeMiniRounded color="secondary"/>
                    {data.active ? (
                        <IOSSwitch defaultChecked={false} checked={data.active}/>
                    ) : (
                        <IOSSwitch disabled/>
                    )}
                </Box>
            </CardActions>
            <CardContent>
                <Typography sx={{wordBreak: 'break-word', overflow: 'hidden'}} fontWeight={700} color="secondary"
                            height={100} fontSize={"2em"}>
                    {label && data[label] !== undefined ? data[label] : label && data[label] === undefined ? 'Geral' : data.name ? data.name.toUpperCase() : data._id}
                </Typography>
            </CardContent>
            <CardActions
                sx={{
                    backgroundColor: "rgba(0, 204, 170, 0.05)",
                }}
            >
                {data.modified_at ? (
                    <Typography
                        fontWeight={"bold"}
                        fontSize={".6em"}
                        color={"secondary"}
                    >
                        ÚLTIMA AÇÃO :{" "}
                        <Typography variant="inherit" color="primary" component={"span"}>
                            {data.modified_at
                                ? format(parseISO(data.modified_at), "dd/MM/yyyy")
                                : ""}
                        </Typography>{" "}
                        ÀS{" "}
                        <Typography variant="inherit" color="primary" component={"span"}>
                            {data.modified_at
                                ? format(parseISO(data.modified_at), "HH:mm", {locale: ptBR})
                                : ""}
                        </Typography>
                    </Typography>
                ) : data.created_at ? (
                    <Typography
                        fontWeight={"bold"}
                        fontSize={".6em"}
                        color={"secondary"}
                    >
                        Criado em :{" "}
                        <Typography variant="inherit" color="primary" component={"span"}>
                            {data.created_at
                                ? format(parseISO(data.created_at), "dd/MM/yyyy")
                                : ""}
                        </Typography>{" "}
                        ÀS{" "}
                        <Typography variant="inherit" color="primary" component={"span"}>
                            {data.created_at
                                ? format(parseISO(data.created_at), "HH:mm", {locale: ptBR})
                                : ""}
                        </Typography>
                    </Typography>
                ) : (
                    <Typography
                        fontWeight={"bold"}
                        fontSize={".5em"}
                        color={"secondary"}
                    >
                        NÃO POSSUI DATA DE MODIFICAÇÃO/CADASTRO
                    </Typography>
                )}
            </CardActions>
        </Card>
    </>
}

export default Block;