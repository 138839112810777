import axios from "axios";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { IUnit, IUnitMutation, ITotal } from "../models/Unit/types";
import { IError } from "../models/Error/types";


function useUnits(){
    const getUnits: UseQueryResult<IUnit[], IError> = useQuery(
        "unit",
        async ()=>{
            return instance.get("/unity/get").then((res)=> res.data);
        }
    )

    async function getUnitsPage({queryKey}){
        return instance.get(`/unity/get?page=${queryKey[1]}`).then((res) => res.data);
    }


    const unityMutation: UseMutationResult<any, IError, any> =
        useMutation(async (values) => {
            return instance.post("/unity/store", values).then((res) => res.data);
        });

    const unityUpdateMutation: UseMutationResult<any, IError, any> =
        useMutation(async (values) => {
            return instance.put(`/unity/update/${values._id}`, values).then((res) => res.data);
        });


    const deleteUnit = (user: IUnit) => {
        return instance.delete(`/unity/delete/${user._id}`);
    }

    return {
        getUnits,
        getUnitsPage,
        deleteUnit,
        unityMutation,
        unityUpdateMutation
    }
}
export default useUnits;
