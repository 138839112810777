import React from "react";
import {
    Box,
    Dialog as MuiDialog,
    DialogActions,
    Pagination,
    Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IDeviceHistory } from "../../models/MonitoringDevices/types";
import { HomeMiniRounded } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { GlobalContext } from "../../contexts";
import { AnimatedOxygenLogo } from "../../helpers";
import { IClient } from "../../models/Client/types";
import { IDialogInfo } from "../../models/Global/types";
import { IUser } from "../../models/User/types";
import InfoLine from "../InfoLine";

function UserInfo({ open, handleClose, data }: IDialogInfo<IUser>) {
    const { page, setPage } = React.useContext(GlobalContext);
    return (
        <MuiDialog
            open={open}
            onClose={handleClose}
            sx={{
                "& .MuiDialogContent-root": {
                    padding: "16px 0px",

                },
                "& .MuiPaper-root": {
                    maxWidth: '70vw'
                }
            }}
        >
            <DialogTitle title="Cliente">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        padding: "0 50px"
                    }}
                >
                    <HomeMiniRounded color="primary" />
                    <Typography color="primary" fontWeight={"bold"}>
                        {data.name.toUpperCase()}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        gap: "2px",
                    }}
                >
                    <InfoLine label="Nome" info={data?.name}></InfoLine>
                    <InfoLine label="E-mail" info={data?.email}></InfoLine>
                    <InfoLine label="Role" info={data?.roles?.join(' - ')}></InfoLine>
                    <InfoLine label="Cor de Fundo" info={data?.background_color}></InfoLine>
                    <InfoLine label="Status" info={data?.active ? 'Ativo' : 'inativo'}></InfoLine>
                </Box>
            </DialogContent>
        </MuiDialog>
    );
}

export default UserInfo;
