import {HomeMiniRounded, LocalHospitalRounded} from "@mui/icons-material";
import HomeMini from "@mui/icons-material/HomeMini";
import {
    Box,
    Card,
    Grid,
    Stack,
    styled,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {format, parseISO} from "date-fns";
import React from "react";
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {useDashboard} from "../../services/useDashboard";
import useMonitoringDevices from "../../services/useMonitoringDevices";
import {useQuery} from "react-query";
import _ from "lodash";

const StyledCard = styled(Card)({
    padding: "10px",
});

function Reports() {
    const {data, isSuccess} = useDashboard();
    const {getMonitoringDevices} = useMonitoringDevices();

    const getMonitoringDevice = useQuery('monitoring', getMonitoringDevices, {
        refetchInterval: 60000,
    })

    const matches = useMediaQuery("(max-width:800px)");
    return (
        <Box
            sx={{
                padding: "40px 20px",
            }}
        >
            <Box>
                <Typography fontSize={"2rem"} fontFamily={"Rubik"} fontWeight={"Bold"}>
                    Relatórios Gráficos
                </Typography>
                <Typography fontFamily="Nunito" color="common.black">
                    Bem vindo ao dashboard do consumo do oxigênio
                </Typography>
            </Box>
            <Grid
                xs={12}
                container
                spacing={2}
                sx={{
                    paddingTop: "10px",
                }}
            >
                <Grid item sm={12} md={8}>
                    <StyledCard
                        variant="outlined"
                        sx={{
                            width: matches ? "320px" : "100%",
                            height: matches ? "300px" : "200px",
                            overflowX: "auto",
                        }}
                    >
                        <ResponsiveContainer width={"100%"} height="100%">
                            {isSuccess ? (
                                <AreaChart
                                    data={data}
                                    margin={{top: 10, right: 30, left: 0, bottom: 0}}
                                    style={{
                                        fontSize: ".8rem",
                                        padding: "10px",
                                    }}
                                >
                                    <defs>
                                        <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#00CCAA" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#00CCAA" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <XAxis
                                        dataKey={"date"}
                                        capHeight={15}
                                        tickFormatter={(tick) => {
                                            return tick === 0 || tick === 'auto' ? tick : format(parseISO(tick), "dd/MM/yyyy")
                                        }}
                                    />
                                    <YAxis/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip
                                        content={(toolProps) => (
                                            <>
                                                <Card
                                                    sx={{
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "1em",
                                                        }}
                                                    >
                                                        Histórico de consumo
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: ".9em",
                                                        }}
                                                    >
                                                        Litros:{" "}
                                                        {toolProps.payload?.length &&
                                                        toolProps?.payload[0].value?.toLocaleString(
                                                            "pt-br",
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </Typography>
                                                </Card>
                                            </>
                                        )}
                                        labelFormatter={() => "Histórico de consumo "}
                                        label="Teste"
                                        // formatter={(value : any, name: any, props: any) => ["Teste", "Testing"] }
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="consumption"
                                        stroke="black"
                                        fillOpacity={1}
                                        fill="url(#green)"
                                    />
                                </AreaChart>
                            ) : (
                                <Box></Box>
                            )}
                        </ResponsiveContainer>
                    </StyledCard>
                </Grid>
                <Grid item sm={12} md={4} container>
                    <Stack spacing={2} sx={{width: "100%"}}>
                        <Grid item xs={12}>
                            <StyledCard
                                variant="outlined"
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        background: "rgba(0, 204, 170, .1)",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "8px",
                                        borderRadius: "50%",
                                    }}
                                >
                                    <LocalHospitalRounded color="primary"/>
                                </Box>
                                <Box>
                                    <Typography
                                        fontFamily={"Rubik"}
                                        fontSize="1em"
                                        fontWeight={"400"}
                                    >
                                        <Typography
                                            fontWeight={"bold"}
                                            variant="inherit"
                                            color="primary"
                                            component={"span"}
                                        >
                                            {getMonitoringDevice.data?.length &&
                                            getMonitoringDevice.data.reduce(
                                                (total, item) => total + item.total,
                                                0
                                            )}
                                        </Typography>{" "}
                                        Leitos Cadastrados
                                    </Typography>
                                </Box>
                            </StyledCard>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledCard
                                variant="outlined"
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        background: "rgba(0, 204, 170, .1)",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "8px",
                                        borderRadius: "50%",
                                    }}
                                >
                                    <HomeMini color="primary"/>
                                </Box>
                                <Box>
                                    <Typography fontFamily={"Rubik"} fontSize="1em">
                                        <Typography
                                            fontWeight={"bold"}
                                            variant="inherit"
                                            color="primary"
                                            component={"span"}
                                        >
                                            {_.sum(_.map(getMonitoringDevice.data, 'total_device')) || 0}
                                        </Typography>{" "}
                                        Dispositivos Cadastrados
                                    </Typography>
                                </Box>
                            </StyledCard>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Reports;
