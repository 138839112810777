import { CloseRounded, Delete } from "@mui/icons-material";
import { Box, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, FormTextField, Notifier } from "../../../components";
import { INotifierProps } from "../../../components/Notifier/Notifier";
import Yup, { YupUtils } from "../../../configs/yup";
import { IAddProps } from "../../../models/Global/types";
import { getRoles, IUser, Roles } from "../../../models/User/types";
import useClient from "../../../services/useClient";
import useRights from "../../../services/useRights";
import useRole from "../../../services/useRole";
import useUser from "../../../services/useUser";

function UsersForm({ setAdd, formData }: IAddProps) {
    const { userMutation, userUpdateMutation, userUpdatePasswordMutation } = useUser();
    const { allRoles } = useRole();
    const { getClients } = useClient();
    const { allRights } = useRights();
    const [notifier, setNotifier] = React.useState<INotifierProps>();
    // const [roles, setRoles] = useState<string[]>([])
    const initialValues = formData ? formData as IUser : {
        name: '',
        email: '',
        roles: ['guest'],
        background_color: '',
        password: '',
        companies_links: [],
        active: true
    }

    const validations = Yup.object().shape({
        name: Yup.string().required(YupUtils.mesages.REQUIRED_MESAGE),
        email: Yup.string().required(YupUtils.mesages.REQUIRED_MESAGE).matches(YupUtils.regexp.EMAIL_REGEXP, YupUtils.mesages.EMAIL_INVALID_MESAGE),
        background_color: Yup.string().required(YupUtils.mesages.REQUIRED_MESAGE).matches(YupUtils.regexp.COLOR_REGEXP, YupUtils.mesages.COLOR_INVALID_MESAGE),
        password: !formData ? Yup.string().required(YupUtils.mesages.REQUIRED_MESAGE) : Yup.string().nullable(),
    });
    function handleSubmit(values: IUser) {

        if (formData) {
            if (values.password) {
                userUpdatePasswordMutation.mutate(values, {
                    onSuccess: (response) => {
                    },
                    onError: (error) => {
                        setNotifier({ open: true, msg: "Erro ao atualizar senha do usuário.", severity: 'error' });
                    },
                    onSettled: () => { },
                });
            }

            userUpdateMutation.mutate(values, {
                onSuccess: (response) => {
                    setNotifier({ open: true, msg: "Usuário Atualizado Com Sucesso!", severity: 'success' });
                    setTimeout(() => setAdd(false), 1000);
                },
                onError: (error) => {
                    setNotifier({ open: true, msg: "Erro ao atualizar usuário.", severity: 'error' });
                },
                onSettled: () => { },
            });

            return
        }

        userMutation.mutate(values, {
            onSuccess: (response) => {
                setNotifier({ open: true, msg: "Usuário Cadastrado Com Sucesso!", severity: 'success' });
                setTimeout(() => setAdd(false)
                    , 1000);
            },
            onError: (error) => {
                setNotifier({ open: true, msg: "Erro ao cadastrar usuário.", severity: 'error' });
            },
            onSettled: () => { },
        });
    }


    return (
        <>
            <Notifier
                msg={userMutation.error?.errorMessage || notifier?.msg}
                closeState={setNotifier}
                severity={notifier?.severity}
                open={notifier?.open}
                closeTime={notifier?.closeTime}
            />
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography fontSize={16} fontWeight="bold" color="secondary">
                    Adicionar Usuario
                </Typography>
                <IconButton onClick={() => setAdd(false)}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validations}
                >
                    {({ setFieldValue, values, initialValues }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid xs={12} item>
                                    <FormTextField name="name" label="Nome" value={values?.name} ></FormTextField>
                                </Grid>
                                <Grid xs={12} item>
                                    <FormTextField name="email" label="E-mail" value={values?.email}></FormTextField>
                                </Grid>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="role">Role</InputLabel>
                                        <Select sx={{ textTransform: 'capitalize' }} name="role" labelId="role" defaultValue={values.roles} onChange={(event) => setFieldValue('roles', [event.target.value])} label="Role">
                                            {
                                                allRoles?.data?.data.map((role) => (<MenuItem sx={{ textTransform: 'capitalize' }} value={role}>{role}</MenuItem>))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={6} item>
                                    <FormTextField name="background_color" label="Cor de fundo" value={values?.background_color.toLocaleUpperCase()} ></FormTextField>
                                </Grid>
                                {values.companies_links.map((vinculo, index) =>
                                    <>
                                        <Grid xs={6} item>
                                            <FormControl fullWidth >
                                                <InputLabel id="vinculo">Vinculo com:</InputLabel>
                                                <Select sx={{ textTransform: 'capitalize' }} name={`companies_links[${index}].company_id`} labelId="vinculo" value={values?.companies_links[index]?.company_id || ''} onChange={(event) => setFieldValue(`companies_links[${index}].company_id`, event.target.value)} label="Vinculo com:">
                                                    {
                                                        getClients?.data?.data.map((client) => (<MenuItem sx={{ textTransform: 'capitalize' }} value={client._id}>{client.name}</MenuItem>))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid xs={5} item>
                                            <FormControl fullWidth>
                                                <InputLabel id="pvinculo">Permissão do vinculo</InputLabel>
                                                <Select sx={{ textTransform: 'capitalize' }} name={`companies_links[${index}].rights`} labelId="pvinculo" value={values?.companies_links[index]?.rights} onChange={(event) => setFieldValue(`companies_links[${index}].rights`, [event.target.value])} label="Permissão do vinculo">
                                                    {
                                                        allRights?.data?.data.map((role) => (<MenuItem sx={{ textTransform: 'capitalize' }} value={role}>{role}</MenuItem>))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }} xs={1} item>
                                            <Tooltip sx={{ alignSelf: 'center', cursor: 'pointer' }} onClick={() => values.companies_links.splice(index, 1)} title="Deletar Vinculo">
                                                <Delete></Delete>
                                            </Tooltip>
                                        </Grid>
                                    </>
                                )}
                                <Grid xs={12} item >
                                    <Box onClick={() => values.companies_links.push({ company_id: '', rights: [] })} sx={{
                                        textAlign: 'center',
                                        padding: '0.3em',
                                        backgroundColor: (theme) => theme.palette.primary.dark,
                                        "&:hover": {
                                            cursor: "pointer",
                                            backgroundColor: (theme) => theme.palette.primary.main,
                                        },
                                        color: "white"
                                    }} >
                                        Adicionar Vinculo
                                    </Box>
                                </Grid>

                                <Grid xs={6} item>
                                    <FormTextField name="password" label="Senha" value={values?.password} ></FormTextField>
                                </Grid>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="active">Status</InputLabel>
                                        <Select name="active" labelId="active" defaultValue={values.active.toString()} onChange={(event) => setFieldValue('active', JSON.parse(event.target.value))} label="Status">
                                            <MenuItem value={'true'}>Ativo</MenuItem>
                                            <MenuItem value={'false'}>Inativo</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} item>
                                    <Button type={"submit"} loading={userMutation.isLoading}>
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </>
    );
}

export default UsersForm;