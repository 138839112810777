import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import BlockWard from "../../../components/BlockWard";
import DeviceBlock from "../../../components/Device/DeviceBlock";
import DeviceListWard from "../../../components/Device/DeviceListWard";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import Notifier, { INotifierProps } from "../../../components/Notifier/Notifier";
import ProductListWard from "../../../components/Product/ProductListWard";
import { GlobalContext } from "../../../contexts";
import useDevice from "../../../services/useDevice";

function Devices() {

    const { view } = useContext(GlobalContext);
    const { getDevicesPage, deleteDevice } = useDevice();
    const [notifier, setNotifier] = useState<INotifierProps>(null);
    const [page, setPage] = useState(1);
    const [openDelete, setOpenDelete] = React.useState<{ open: boolean, valueToDelete: any }>({ open: false, valueToDelete: null });
    function handleCloseDelete(): void {
        setOpenDelete({ open: false, valueToDelete: null });
    }

    const currents = useQuery(['devices', page], getDevicesPage, {
        refetchInterval: 60000,
    })

    function handlePaginator(page: number) {
        setPage(page)
    }

    function handleDelete() {
        deleteDevice(openDelete.valueToDelete).then(
            () => setNotifier({ open: true, severity: 'success', msg: 'Cadastro deletado com sucesso!' }),
            () => setNotifier({ open: true, severity: 'error', msg: 'Erro ao deletar cadastro.' }),
        )
        handleCloseDelete();
    }

    useEffect(() => {
        currents.refetch();
    }, [page])

    if (currents.isSuccess && currents.data) {

        if (view === "block")
            return (
                <Grid
                    container
                    gap={2}
                    padding={view === "block" ? "20px" : ""}
                    sx={{
                        overflowY: "auto",
                    }}
                >
                    <>
                        <BlockWard title="Dispositivos" _id="dispositivos">
                            {currents.data.data.map((item) => (
                                <DeviceBlock data={item} label='teese' key={item._id} />
                            ))}
                        </BlockWard>
                    </>
                </Grid>
            );
        else return <>
            <Notifier
                msg={notifier?.msg}
                closeState={setNotifier}
                severity={notifier?.severity}
                open={notifier?.open}
                closeTime={notifier?.closeTime}
            />
            <DeviceListWard isPageable={true} setDelete={setOpenDelete} limit={currents.data.limit} total={currents.data.total} handlePaginator={handlePaginator} data={currents.data.data}></DeviceListWard>
            <DeleteDialog open={openDelete.open} handleClose={handleCloseDelete} onConfirmDelete={handleDelete} name="deleteDialog" ></DeleteDialog>
        </>
    }
    return <></>
}

export default Devices;