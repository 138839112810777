import { useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { IError } from "../models/Error/types";
import { IRequestConfig } from "../models/Global/types";

function useRights() {
    
    const allRights: UseQueryResult<IRequestConfig<string[]> ,IError> = useQuery(
        "right",
        async () => {
          return instance.get("right/get").then((res) => res.data);
        },
        {
          refetchInterval: 60000,
        }
      );

    return {allRights}
    
  }
  
  export default useRights;