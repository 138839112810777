import axios from "axios";
import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryResult,
} from "react-query";
import instance from "../configs/api";
import {
    IConfigDevice,
    IDeviceFreeMutation,
    IDeviceMutation,
    IFreeDevice,
    IpDevice,
    ITotal,
} from "../models/MonitoringDevices/types";
import {IError} from "../models/Error/types";
import {IUnit} from "../models/Unit/types";

import {IWard} from "../models/MonitoringDevices/types";

function useMonitoringDevices() {
    // const getMonitoringDevice: UseQueryResult<IWard[], IError> = useQuery(
    //     "monitoring",
    //     async () => {
    //         return instance.get("/monitoring/getAllByClient").then((res) => res.data);
    //     },
    //     {
    //         // refetchInterval: 3000,
    //         retry: 10,
    //         retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
    //     }
    // );

    const getMonitoringDevices = () => {
        return instance.get("/monitoring/getAllByClient").then((res) => res.data);
    }

    const activeDevices = () => {
        return instance.get("/monitoring/total").then((res) => res.data);
    };

    const getfreeDevices: UseMutationResult<IDeviceFreeMutation, IError, IUnit> =
        useMutation((values) => {
            const {_id} = values;
            return instance.post("/device/link", {unity: _id});
        });

    const findIps: UseMutationResult<any, IError> = useMutation(() => {
        return instance.get("/ipdevice/get");
    });

    const deviceFailure = (data: IFreeDevice) => {
        return instance
            .post("/device/link/failure", {data})
            .then((res) => res.data);
    };
    const findOne = (id: string, page: number = 1) => {
        return instance
            .get(`/monitoring/get/${id}?limit=6&page=${page}`)
            .then((res) => {
                return res.data;
            });
    };

    const config: UseMutationResult<IDeviceMutation, IError, IConfigDevice> =
        useMutation((values) => {
            const {ip, id, idunidade, url, time, ssid, password, resolution, calibration} = values;
            let urlBase = `http://${ip}:80`;
            const urld = axios.create({
                baseURL: urlBase,
                timeout: 3000,
                withCredentials: false,
            });
            return urld.post(
                `/configuration`,
                {
                    id: id,
                    idunidade: idunidade,
                    url: url,
                    time: time,
                    ssid: ssid,
                    password: password,
                    resolution: resolution,
                    calibration: calibration,
                },
                {
                    headers: {
                        "Content-Security-Policy": "upgrade-insecure-requests",
                    },
                }
            );
        });
    return {
        findIps,
        getMonitoringDevices,
        findOne,
        activeDevices,
        config,
        getfreeDevices,
        deviceFailure,
        // getMonitoringDevice,
    };
}

export default useMonitoringDevices;
