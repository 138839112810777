import React, { useContext } from "react";
import {
  Box,
  List,
  Divider,
  Drawer,
  useMediaQuery,
  Table,
  ListItem,
} from "@mui/material";
import SidebarContent from "./SidebarContent";
import SidebarNav from "./SidebarNav";
import SidebarSubNav from "./SidebarSubNav";
import SidebarUserProfile from "./SidebarUserProfile";
import { items } from "./Data/sidebarData";
import ListItemLink from "../ListItemLink/ListItemLink";
import { AuthContext, GlobalContext } from "../../contexts";
import SidebarDevices from "./SidebarDevices";
import useMonitoringDevices from "../../services/useMonitoringDevices";
import {QueryObserver, useQuery, useQueryClient, UseQueryResult} from "react-query";
import { IActives } from "../../models/MonitoringDevices/types";
import { IError } from "../../models/Error/types";
const navWidth = 300;
interface SidebarProps { }
function Sidebar(props: SidebarProps) {
  const matches = useMediaQuery("(min-width:900px)");
  const { access_token, roles } = React.useContext(AuthContext);
  const { activeDevices } = useMonitoringDevices();
  const [variant, setVariant] = React.useState<"persistent" | "temporary">(
    "persistent"
  );
  const query = useQuery<UseQueryResult<IActives, IError>>(
    "devicesStatus",
    () => activeDevices(),
    {
      refetchInterval: 10000,
    }
  );
  React.useEffect(() => {
    if (matches) {
      setVariant("persistent");
    } else {
      setVariant("temporary");
    }
  }, [matches]);
  const { sidebar, setSidebar } = useContext(GlobalContext);
  return (
    <>
      {access_token ? (
        <Box sx={{ display: "flex" }}>
          <Drawer
            sx={(theme) => ({
              height: "100vh",
              display: "flex",
              width: navWidth,
              flexDirection: "column",
              background: "white",
              borderRight: `1px solid ${theme.palette.divider}`,
              "& .MuiPaper-root": {
                width: navWidth,
              },
            })}
            open={sidebar}
            onClose={() => setSidebar(false)}
            variant={variant}
          >
            <SidebarUserProfile />
            <Box mt={9}>
              <List>
                <Divider variant="middle" />
                {items.map((item) => {
                  if (item.permission === roles[0] || roles[0] === 'super') {
                    if (!item.children) {
                      return (
                          <ListItemLink
                              key={item.name}
                              name={item.name}
                              to={item.to}
                              icon={item.icon}
                          />
                      );
                    } else {
                      return (
                          <>
                            <ListItemLink
                                key={item.name}
                                name={item.name}
                                to={"#"}
                                icon={item.icon}
                            >

                              {item.children.map((children) => (<ListItemLink
                                  key={children.name}
                                  name={children.name}
                                  to={children.to}
                                  icon={children.icon}
                              ></ListItemLink>))}
                            </ListItemLink>
                          </>
                      );
                    }
                  }
                })}
              </List>

              <SidebarDevices status={query.data} />
            </Box>
          </Drawer>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                position: "fixed",
                width: "calc(100% - 280px)",
                zIndex: 1000,
              }}
            >
              <SidebarNav />
              <SidebarSubNav />
            </Box>

            <SidebarContent />
          </Box>
        </Box>
      ) : (
        <SidebarContent />
      )}
    </>
  );
}

export default Sidebar;
